import {
  Card,
  Col,
  Row
} from "antd";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from "react";
import { CardBody, Container } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';
import { useNavigate, useParams } from "react-router-dom";
import PageBack from '../../assets/images/back.svg';
import { default as Begin01, default as Begin02, default as Begin03 } from '../../assets/images/begin02.jpg';
import Header from "../../components/layout/Header";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import Footer from "../../components/layout/Footer";


function News() {
  const { request } = useRequest()
  const [resource, setResource] = useState();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
 
  const navigate = useNavigate()
  const { id } = useParams()

  const getResource = () => {
    setLoading(true)
    request({
      url: `/common/getResources/${id}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        //setData(data.data);
        setResource(data)
      },
    });
  }; 

 
  const getNews = () => {
    setLoading(true)
    request({
      url: `/common/getNews`,
      method: "GET",
      onSuccess: (res) => {
        const { data, status } = res;
        console.log(data, "News");
        setData(data.articles);
      },
    });
  };

  useEffect(() => {
    getNews()
  }, [])
  
  useEffect(() => {
    getResource()

  }, [])

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} className="page-back">
                  <img src={PageBack} />Back
                </div>
              </Col>
              <Col sm={24} md={16}>
                <Row gutter={12}>

                  <Col md={24}>
                    <div className="resources-card-p">
                      <Card>
                        <CardBody className="resources-body ">
                          <img className="resoureces-img" src={resource?.image}></img>
                          <h5>{resource?.title}</h5>
                          <p>{resource?.description}</p>

                        </CardBody>
                      </Card>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={24} md={8}>
                <div className="invest-card">
                  <Row>
                    {
                      data.map(item=>
                      <Col md={24} className="mb-3" key={item._id}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img height={220} src={item.image} onError={(e)=>e.target.src=Begin01}></img>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    )}
                   
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

      </section>
      <Footer />
    </>
  );
}



export default News;
