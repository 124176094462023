import React, { useEffect, useState } from "react";

// const TradingViewGoldWidget = () => {
// useEffect(() => {
//     const script = document.createElement('script');

//     script.type = 'text/javascript';
//     script.async = true;
//     script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-info.js';
//     script.innerHTML = JSON.stringify({
//         "symbol": "OANDA:XAUUSD",
//         "width": '100%',
//         "showSymbolLogo": false,
//         "locale": "en",
//         "colorTheme": "light",
//         "isTransparent": false,
//     });

//     document.getElementsByClassName('tradingview-widget-container__widget_gold')[0].appendChild(script);

//     return () => {
//         // Cleanup
//         if(document.getElementsByClassName('tradingview-widget-container__widget_gold')[0]?.innerHTML)  document.getElementsByClassName('tradingview-widget-container__widget')[0].innerHTML = '';
//     };
// }, []);

//     return (
//         <div className="tradingview-widget-container">
//             <div className="tradingview-widget-container__widget_gold"></div>

//         </div>
//     );
// };

// export const TradingViewGoldWidget = () => {
//   const [theme, setTheme] = useState(false);

//   useEffect(() => {
//     const webTheme = localStorage.getItem('theme');
//     setTheme(webTheme);
//   }, []);

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.async = true;
//     if (theme === true || theme == "true") {
//       script.src = `https://widget.nfusionsolutions.com/widget/script/chart/1/b7140477-6a0d-44bb-b2af-92b65d32326c/a78b82ea-2634-4f34-a66b-202807dac9ad?symbols=gold`;
//     } else {
//       script.src = `https://widget.nfusionsolutions.com/widget/script/chart/1/b7140477-6a0d-44bb-b2af-92b65d32326c/54b3cb27-729e-4c24-8e0c-c4419ac81968?symbols=gold`;
//     }

//     const firstScript = document.getElementsByTagName('script')[0];
//     firstScript.parentNode.insertBefore(script, firstScript);

//     return () => {
//       script.parentNode.removeChild(script);
//     };
//   }, [theme]);

//   return (
//     <div>
//       {theme === true || theme == "true" ? (
//         <div
//           id="a78b82ea-2634-4f34-a66b-202807dac9ad"
//           className="nfusion-chart"
//           style={{ width: '100%', height: '100%' }}
//         ></div>
//       ) : (
//         <div
//           id="54b3cb27-729e-4c24-8e0c-c4419ac81968"
//           className="nfusion-chart"
//           style={{ width: '100%', height: '100%' }}
//         ></div>
//       )}
//     </div>
//   );
// };

export const TradingViewGoldWidget = () => {
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    const webTheme = localStorage.getItem('theme');
    setTheme(webTheme);
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    const chartId = theme ? 'a78b82ea-2634-4f34-a66b-202807dac9ad' : '54b3cb27-729e-4c24-8e0c-c4419ac81968';

    script.src = `https://widget.nfusionsolutions.com/widget/script/chart/1/b7140477-6a0d-44bb-b2af-92b65d32326c/${chartId}?symbols=gold`;

    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    return () => {
      script.parentNode.removeChild(script);
    };
  }, [theme]);

  return (
    <div
      id={theme ? 'a78b82ea-2634-4f34-a66b-202807dac9ad' : '54b3cb27-729e-4c24-8e0c-c4419ac81968'}
      className="nfusion-chart"
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
};
export default TradingViewGoldWidget;
