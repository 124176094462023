import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Select,
} from "antd";
import Logo from "../../assets/images/logo-black.svg";
import LogoWhite from "../../assets/images/logo-white.svg";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import Loader from "../../components/Loader";
import encryptDecrypt from "../../helper/encryptDecrypt";
import apiUrl from "../../constants/api_urls";
import {
  getClientIP,
  getClientLocation,
  getClientBrowserName,
} from "../../helper/functions";
import lang from "../../helper/langHelper";
const { Title } = Typography;
const { Content } = Layout;

// ipAddress
const ForgotPassword = () => {
  const { setEmail, setPassword } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;
    let payload = {
      email: email,
      password: password,
    };
    setEmail(email);
    setPassword(password);
    onSubmit(payload);
  };

  const onSubmit = (values) => {
    const api = {
      login: apiUrl.forgotPassword,
    };
    const { email, password } = values;
    if (!email)
      return ShowToast("Please enter email to forgot password", Severty.ERROR);

    const payload = {};
    payload.email = email;
    setLoading(true);
    request({
      url: api.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        console.log("Success Data:", data);
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          setTimeout(() => navigate("/otp"), 200);
          var emailResetEncrypt = encryptDecrypt.encryptEmail(email);
          localStorage.setItem("UGwdfdR2uHMM24N", emailResetEncrypt);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {/* {
        loading && <Loader />
      } */}
      <Layout className="layout-default layout-signin">
        <Content className="signin d-none d-md-block">
          <Row className=" align-items-center">
            <Col span={24} sm={6} lg={6} md={6}>
              <div className="signup-logo d-dark-none">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
              <div className="signup-logo d-dark-block">
                <img src={LogoWhite} alt="" onClick={() => navigate("/")} />
              </div>
            </Col>
            <Col span={24} sm={18} lg={18} md={18}>
              <div className="signup-right">
                <Link className="sign-in-link-type" type="button">
                  Already have a mynt account?
                </Link>
                <Button
                  className="sign-in-btn"
                  type="button"
                  onClick={() => navigate("/login")}
                  // htmlType="submit"
                >
                  Sign in
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="signin-box">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="signup-form">
                <Title className="tital-text-sign mb-15">Forgot Password</Title>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username-form"
                    label="Email ID"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        max: 255,
                        message: "Email address not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input
                      className="form-of-type"
                      placeholder="Enter Email ID"
                    />
                  </Form.Item>

                  <Form.Item className="signin-footer">
                    <Button
                      className="ant-btn-default"
                      type="submit"
                      htmlType="submit"
                      loading={loading}
                      // onClick={() => navigate("/")}
                    >
                      Send Otp
                    </Button>
                    <Button
                      className="ant-btn-default"
                      type="button"
                      onClick={() => navigate(-1)}
                      htmlType="submit"
                    >
                      {lang("Back")}
                    </Button>
                  </Form.Item>
                  <div className=" d-block d-md-none">
                    <div className="signup-right">
                      <Link className="sign-in-link-type" type="button">
                        Don’t have a mynt account?
                      </Link>
                      <Button
                        className="sign-in-btn"
                        type="button"
                        onClick={() => navigate("/signup")}
                        htmlType="submit"
                      >
                        Create an account
                      </Button>
                    </div>
                  </div>
                  <div className="signin-bottam-bar">
                    <span>For trademarks and patents, please see our</span>
                    <span
                      className="link-span"
                      onClick={() => navigate("/legal-notice")}
                    >
                      {" "}
                      Legal Notice.
                    </span>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default ForgotPassword;
