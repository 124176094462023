import {
    Card,
    Collapse
} from "antd";
import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import 'react-phone-input-2/lib/style.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Buy from "./_Buy";
import Sell from "./_Sell";
import Swap from "./_Swap";
import lang from "../../helper/langHelper";
const { Panel } = Collapse;

const OrderCard = ({ }) => {

    const [key, setKey] = useState('buy');

    return (
        <div className="invest-card">
            <Card className="mb-3">
                {/* <div className="investment-nav-bar">
        <Link className="investment-btn active" type="button" >Buy</Link>
        <Link className="investment-btn " type="button" >Sell</Link>
        <Link className="investment-btn" type="button" >Swap</Link>
      </div> */}
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="investment-nav-bar mb-3"
                >
                    <Tab className="investment-btn" eventKey="buy" title={lang("Buy")}>
                        <Buy />
                    </Tab>
                    <Tab className="investment-btn" eventKey="Sell" title={lang("Sell")}>
                        <Sell />
                    </Tab>
                    <Tab className="investment-btn" eventKey="Swap" title={lang("Swap")}>
                        <Swap />
                    </Tab>
                </Tabs>

            </Card>
            {/* <Card>
                <div className="metals-outer">
                    <h6>Browse other metals</h6>
                    <div className="metals-body">
                        <img src={componentsilver} /> 
                        <div className="metals-text">
                            <h4>Silver</h4>
                            <p>$2,210.77 USD</p>
                            <span>-137.95 (-5.88%)</span>
                        </div>
                    </div>
                    <div className="metals-body">
                        <img src={componentplatinum} /> 
                        <div className="metals-text">
                            <h4>Platinum</h4>
                            <p>$2,210.77 USD</p>
                            <span>-137.95 (-5.88%)</span>
                        </div>
                    </div>
                    <div className="metals-body">
                        <img src={componentpalladium} /> 
                        <div className="metals-text">
                            <h4>Palladium</h4>
                            <p>$2,210.77 USD</p>
                            <span>-137.95 (-5.88%)</span>
                        </div>
                    </div>
                </div>
            </Card> */}
        </div>)
}


export default OrderCard