import axios from 'axios';
import React, { useState, useContext, useEffect } from "react";
import apiPath from '../constants/api_urls';
import { AuthContext } from "../context/AuthContext";
import { ShowToast,Severty } from '../helper/toast';
const client = axios.create({
    baseURL: apiPath.baseURL
});

const useRequest = () => {
    const { logout } = useContext(AuthContext)
    const request = async ({ url, method: tmethod, data, onSuccess, onError, header }) => {
        const method = tmethod.trim().toUpperCase()
        let token = localStorage.getItem("token") ? localStorage.getItem("token") : '';

        const headers = {
            ...header,
            Authorization: `Bearer ${token}`
        };

        try {

            const response = await client({
                url,
                method,
                data,
                headers: { ...headers },
            })

            if (onSuccess) {
                // console.log(response.data, "response")
                onSuccess(response.data)
            }

            return response.data
        }
        catch (err) {
            console.log(err)
            console.log(err, "Error");
            if (err?.response?.code === "ERR_NETWORK") {
                console.log("ErrorNetwork");
            }
            if (err?.response?.status === 401) {
                logout();
            }
            if (err?.response?.status === 403) {
                logout();
            }
            if (err?.response?.data?.message === "jwt expired") {
                logout();
            }

            if (err?.response?.status === 400) {
                if (err.response.data?.errors?.length) {
                    err.response.data?.errors?.map(item => ShowToast(item?.message, Severty.ERROR))
                }
            }

            if (onError) {
                console.log(err);
                onError(err);
            }
            //throw err;
        }
    }

    return { request }
};

export default useRequest;