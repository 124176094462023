import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Select,
} from "antd";
import Logo from "../../assets/images/logo-black.svg";
import LogoWhite from "../../assets/images/logo-white.svg";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import Loader from "../../components/Loader";
import encryptDecrypt from "../../helper/encryptDecrypt";
import apiUrl from "../../constants/api_urls";
import {
  getClientIP,
  getClientLocation,
  getClientBrowserName,
} from "../../helper/functions";
import lang from "../../helper/langHelper";
const { Title } = Typography;
const { Content } = Layout;

// ipAddress
const NewPassword = () => {
  const { setEmail, setPassword } = useAppContext();
  const [loading, setLoading] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, newPassword } = values;
    let payload = {
      email: email,
      password: newPassword,
    };
    setEmail(email);
    setPassword(newPassword);
    handleResetPassword(payload);
  };

  const handleResetPassword = (values) => {
    const { email, password } = values;
    let payload = {};
    const savedEmail = localStorage.getItem("UGwdfdR2uHMM24N");
    var originalEmail = encryptDecrypt.decryptEmail(savedEmail);
    payload.email = originalEmail;

    payload.password = password;
    request({
      url: apiUrl.resetPassword,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setTimeout(() => navigate("/login"), 200);
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
      },
    });
  };

  return (
    <>
      {/* {
        loading && <Loader />
      } */}
      <Layout className="layout-default layout-signin">
        <Content className="signin d-none d-md-block">
          <Row className=" align-items-center">
            <Col span={24} sm={6} lg={6} md={6}>
              <div className="signup-logo d-dark-none">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
              <div className="signup-logo d-dark-block">
                <img src={LogoWhite} alt="" />
              </div>
            </Col>
            <Col span={24} sm={18} lg={18} md={18}>
              <div className="signup-right">
                <Link className="sign-in-link-type" type="button">
                  {lang("Already have a mynt account?")}
                </Link>
                <Button
                  className="sign-in-btn"
                  type="button"
                  onClick={() => navigate("/login")}
                  // htmlType="submit"
                >
                  {lang("Sign in")}
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="signin-box">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="signup-form">
                <Title className="tital-text-sign mb-15">
                  {lang("Create a New Password")}
                </Title>
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username-form billing-form"
                    label={lang("New Password")}
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the new password!"),
                      },
                    ]}
                  >
                    <Input.Password
                      className="form-of-type"
                      placeholder={lang("New Password")}
                    />
                  </Form.Item>

                  <Form.Item
                    className="username-form billing-form"
                    label={lang("Confirm Password")}
                    name="confirm"
                    dependencies={["newPassword"]}
                    // hasFeedback
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter the confirm password!"),
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              lang("Confirm password & password does not match!"),
                            ),
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      className="form-of-type"
                      placeholder={lang("Password")}
                    />
                  </Form.Item>

                  <Form.Item className="signin-footer">
                    <Button
                      className="ant-btn-default"
                      type="submit"
                      htmlType="submit"
                      loading={loading}
                      // onClick={() => navigate("/")}
                    >
                      {lang("Update Password")}
                    </Button>
                  </Form.Item>
                  <div className=" d-block d-md-none">
                    <div className="signup-right">
                      <Link className="sign-in-link-type" type="button">
                        {lang("Don’t have a mynt account?")}
                      </Link>
                      <Button
                        className="sign-in-btn"
                        type="button"
                        onClick={() => navigate("/signup")}
                        htmlType="submit"
                      >
                        {lang("Create an account")}
                      </Button>
                    </div>
                  </div>
                  <div className="signin-bottam-bar">
                    <span>{lang("For trademarks and patents, please see our")}</span>
                    <span
                      className="link-span"
                      onClick={() => navigate("/legal-notice")}
                    >
                      {" "}
                      {lang("Legal Notice.")}
                    </span>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default NewPassword;
