import {
  Button,
  Col,
  Row
} from "antd";

import 'react-phone-input-2/lib/style.css';
import { Severty, ShowToast } from "../../helper/toast";

import React, { useContext, useState } from "react";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";

import { useNavigate } from "react-router-dom";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import securepayment from '../../assets/images/secure-payment-fill.png';
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { BackArrow } from "./Index";
import { useAppContext } from "../../context/AppContext";

function PeymentMethod() {
  const { request } = useRequest();
  const { setUserProfile } = useContext(AuthContext);

  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { isMobile } = useAppContext();

  const onSubmit = (values) => {
    setLoading(true)
    request({
      url: '/v1/webaapi/login',
      method: 'POST',
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          console.log(data)
          ShowToast(data.message, Severty.SUCCESS)
          setUserProfile(data.data)
        } else {
          ShowToast(data.message, Severty.ERROR)
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  }


  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> :
              <BackArrow />
            }
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">
                  Payment Method
                </div>
                <div className="payment-methods">
                  <img src={securepayment} />
                  <h4>No Payment Methods Available</h4>
                  <div className="password-page-btn mt-4">
                    <Button className="security-btn">Add New Payment Method</Button>
                  </div>
                </div>

              </div>
            </Col>

          </Row>
        </div>


      </section>
      <Footer />
    </>
  );
}



export default PeymentMethod;
