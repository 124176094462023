import React from "react";
import { Form, Input, Button, Modal } from "antd";
import { useNavigate } from "react-router";

const CreditCardForm = ({ show ,hide }) => {
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    // await onFinish(values);
    navigate(-1);
  };

  return (
    <>
      <Modal
        className="modal01"
        title={"Bank Wire"}
        footer={false}
        show={show}
        // hide={hide}
      >
        <Form
          name="credit_card"
          onFinish={handleSubmit} // Use the handleSubmit function
          layout="vertical"
          style={{ marginTop: 20 }}
        >
          <Form.Item
            label="Card Number"
            name="cardNumber"
            rules={[{ required: true, message: "Please input your card number!" }]}
          >
            <Input placeholder="Card Number" />
          </Form.Item>

          <Form.Item
            label="Card Holder Name"
            name="cardHolderName"
            rules={[{ required: true, message: "Please input your name!" }]}
          >
            <Input placeholder="Card Holder Name" />
          </Form.Item>

          <Form.Item
            label="Expiry Date"
            name="expiryDate"
            rules={[{ required: true, message: "Please input the expiry date!" }]}
          >
            <Input placeholder="MM/YY" />
          </Form.Item>

          <Form.Item
            label="CVV"
            name="cvv"
            rules={[{ required: true, message: "Please input the CVV!" }]}
          >
            <Input placeholder="CVV" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>

  );
};

export default CreditCardForm;
