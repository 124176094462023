import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";
ChartJS.register(ArcElement, Tooltip, Legend);

const BreakDownChart = ({ gold, silver }) => {
  let { theme } = useAppContext();

  console.log(theme, "theme---------->");

  const data = {
    labels: [lang("Gold"), lang("Silver")],
    datasets: [
      {
        data: [gold ?? 0, silver ?? 0], // Example data, you can replace this with your own values
        backgroundColor: ["#FFA523", "#9F49A3"],
        hoverBackgroundColor: ["#FFA523", "#9F49A3"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "right",
        fullSize: true,
        align: "start",
        class: "graph-rep",
        labels: {
          padding: 18,
          textAlign: "center",
          color: theme === "true" ? "white" : "#252420",
          pointStyle: "circle",
          usePointStyle: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // Get the index of the hovered item
            var index = context.dataIndex;
            // Get the label based on the index
            var label = data.labels[index];
            // Get the value based on the index
            var value = context.dataset.data[index];
            return label + ": " + value.toFixed(2) + "%";
          },
        },
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default BreakDownChart;
