import React, { useEffect, useState } from "react";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import moment from "moment";
import useRequest from "../../../hooks/useRequest";
import { Spinner } from "react-bootstrap";
import { useAuthContext } from "../../../context/AuthContext";

const ChartGold = () => {
  const options = { style: "currency", currency: "USD" };

  const numberFormat = new Intl.NumberFormat("en-US", options);
  const [data, setData] = useState([]);

  const { request } = useRequest();
  const { isDarkTheme } = useAuthContext();
  const [theme, setTheme] = useState(isDarkTheme ? "dark" : "light"); // Theme state

  const getVault = (text) => {
    request({
      url: `/app/chart/gold?type=${text}`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        const newData = data
          .map((item) => {
            const { price, timestamp } = item;
            return [timestamp, price];
          })
          .sort((a, b) => a.timestamp - b.timestamp);
        setData(newData);
      },
    });
  };

  useEffect(() => {
    getVault("2Y");
  }, []);

  const handleRangeSelect = (event) => {
    const { rangeSelectorButton } = event;
    if (!rangeSelectorButton) return;
    getVault(rangeSelectorButton.text);
  };

  // Dynamic chart configuration based on the theme
  const configPrice = {
    chart: {
      height: 500,
      backgroundColor: theme === "dark" ? "#0D0D0D" : "#fff",
      style: {
        color: theme === "dark" ? "#fff" : "#000",
      },
      zoomType: null,
    },
    // title: {
    // //   text: 'Gold Price',
    //   style: {
    //     color: theme === 'dark' ? '#0D0D0D' : '#000',
    //   },
    //   align: 'left',
    // },
    xAxis: {
      type: "datetime",
      events: {
        afterSetExtremes: handleRangeSelect,
      },
      labels: {
        style: {
          color: theme === "dark" ? "#fff" : "#000",
        },
      },
    },
    yAxis: [
      {
        offset: 20,
        labels: {
          formatter: function () {
            return numberFormat.format(this.value);
          },
          x: -15,
          style: {
            color: theme === "dark" ? "#fff" : "#000",
            position: "absolute",
          },
          align: "left",
        },
      },
    ],
    legend: {
      enabled: false,
      itemStyle: {
        color: theme === "dark" ? "#0D0D0D" : "#000",
      },
    },
    plotOptions: {
      area: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, theme === "dark" ? "#cfeac9" : "#b6d0ff"],
            [1, theme === "dark" ? "#000" : "#f2f6ff"],
          ],
        },
        marker: {
          radius: 2,
        },
        lineWidth: 1,
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        threshold: null,
      },
    },
    rangeSelector: {
      buttons: [
        {
          type: "day",
          count: 1,
          text: "1D",
        },
        {
          type: "day",
          count: 7,
          text: "1W",
        },
        {
          type: "month",
          count: 1,
          text: "1M",
        },
        {
          type: "year",
          count: 1,
          text: "1Y",
        },
        {
          type: "year",
          count: 2,
          text: "2Y",
        },
        {
          type: "year",
          count: 5,
          text: "5Y",
        },
        // {
        //     type: 'all',
        //     text: 'All',
        // }
      ],
    },

    tooltip: {
      shared: true,
      formatter: function () {
        return (
          numberFormat.format(this.y, 0) +
          "</b><br/>" +
          moment(this.x).format("lll")
        );
      },
      style: {
        color: "#000",
      },
    },

    credits: {
      enabled: false,
    },

    series: [
      {
        type: "area",
        name: "GOLD",
        data: data,
        color: theme === "dark" ? "#65C953" : "#0071A7",
        tooltip: {
          valueDecimals: 2,
        },
      },
    ],
  };

  return (
    <div>
      {/* <button onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}>
        Toggle Theme
      </button> */}
      <ReactHighcharts config={configPrice}> {<Spinner />} </ReactHighcharts>
    </div>
  );
};

export default ChartGold;
