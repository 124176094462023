import {
    Row,
    Col,
    Button,
    Card,
    Form,
    Input,
    Select,
    Table,
    Tabs,

} from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { CardBody, CardFooter, CardTitle, Container, ModalBody, ModalFooter, ModalTitle,Nav , Navbar,} from "react-bootstrap";
import Logo from '../../assets/images/logo-white.svg'
import Usd from '../../assets/images/currency-usd.png'
import Exchange from '../../assets/images/exchange.png'
import User from '../../assets/images/user-bold.png'
import Nottfy from '../../assets/images/bell-outline.png' 
import ReactApexChart from "react-apexcharts";
import NottfyBell from "../../assets/images/black_bell.svg"
import TabPane from "antd/es/tabs/TabPane";
import Iconspace from '../../assets/images/icon-spase.png'
import Dotted from '../../assets/images/dotted.svg'
import News01 from '../../assets/images/news01.svg'
import News02 from '../../assets/images/news02.svg'
import News03 from '../../assets/images/news03.svg'
import News04 from '../../assets/images/news04.svg'
import AddBtn from '../../assets/images/add-btn.svg'
import componentgold from '../../assets/images/component-gold.svg'
import componentsilver from '../../assets/images/component-silvar.svg' 
import { Footer } from "antd/lib/layout/layout"; 
import payments01 from '../../assets/images/Possiblepayments01.svg'
import payments02 from '../../assets/images/Possible-payments.svg'
import payments03 from '../../assets/images/Possible-payments 02.svg'
import payments04 from '../../assets/images/Possible-payments 03.svg'
import Playstore from '../../assets/images/play-app-store.svg'
import iphone from '../../assets/images/iphone-app-store.svg'
import Toperrow from '../../assets/images/top-errow.svg'  
import Paymentmethod from '../../assets/images/payment-method.svg'
import lang from "../../helper/langHelper";


function GoldReview() {
    const { request } = useRequest()
    const [searchText, setSearchText] = useState('');
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [customerGraphData, setGraphCustomerData] = useState({});
    const [visible, setVisible] = useState(false);
    const debouncedSearchText = useDebounce(searchText, 300);
    const [viewData, setViewData] = useState();
    const [tabValue, setTabValue] = useState();
    const [productList, setProductList] = useState([]);
    const [visibleView, setVisibleView] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        customerMonthlyCount({})
    }, [])


    const handleTabChange = (status) => {
        setTabValue(status)
    };

    const customerMonthlyCount = (data) => {
        const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
        const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

        const customerChartData = {
            series: [
                {
                    name: "User",
                    data: Ccounts,
                },
            ],
            options: {
                chart: {
                    height: 250,
                    type: "line",
                    zoom: {
                        enabled: false,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: "straight",
                },

                grid: {
                    row: {
                        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                        opacity: 0.5,
                    },
                },
                xaxis: {
                    categories: Cmonths,
                },
            },
        };
        setGraphCustomerData(customerChartData);
    };

    useEffect(() => {
        customerMonthlyCount({ userMonthlyGraph: [{ count: 14, month: "December 2023" }, { count: 20, month: "January 2024" }] })
    }, [])
    

    return (
<>
        <section className="main">
              <div className="categrory-header">
                  <ul>
                    <li className="categrory-nav">
                      <p>{lang("Live Spot Prices:")}</p>
                    </li>
                    <li className="categrory-nav">
                      <h6>{lang("Gold:")}</h6>
                      <h6> $2,045.42</h6>
                      <span><img src={Toperrow} />$0.02</span>
                    </li>
                    <li className="categrory-nav">
                      <h6>{lang("Silver:")}</h6>
                      <h6> $2,045.42</h6>
                      <span> <img src={Toperrow} />$0.02</span>
                    </li>
                    <li className="categrory-nav">
                      <h6>{lang("Platinum:")}</h6>
                      <h6> $2,045.42</h6>
                      <span><img src={Toperrow} />$0.02</span>
                    </li>
                    <li className="categrory-nav">
                      <h6>{lang("Palladium:")}</h6>
                      <h6> $2,045.42</h6>
                      <span><img src={Toperrow} />$0.02</span>
                    </li>
                  </ul>
                </div>
                <header>
                  <Navbar expand="lg" className="p-0">
                      <Navbar.Brand href="#"><img src={Logo} /></Navbar.Brand>
                      <Navbar.Toggle aria-controls="navbarScroll" />
                      <Navbar.Collapse id="navbarScroll">
                        <Nav
                          className="me-auto   nav-items-name" >
                          <Nav.Link href="#"className="nav-menu ">{lang("Home")}</Nav.Link>
                          <Nav.Link href="#"className="nav-menu active">{lang("Market")}</Nav.Link>
                          <Nav.Link href="#"className="nav-menu"> {lang("Portfolio")} </Nav.Link>
                          <Nav.Link href="#"className="nav-menu"> {lang("FAQ")} </Nav.Link>
                        </Nav>
                        <div className="btn-header">
                          <Button className="ant-btn-header" type="button" ><img src={Usd} />   $  </Button>
                          <Button className="ant-btn-header" type="button"><img src={Exchange} />  {lang("Transfer")}  </Button>
                          <Button className="ant-btn-header" type="button"><img src={User} />  {lang("Account")}  </Button>
                          <Button className="ant-btn-header" type="button"><img src={Nottfy} /> </Button>
                        </div>
                      </Navbar.Collapse>
                  </Navbar>
                </header>


            <Container>
                <div className="home-outers">
                    <Row gutter={24}>
                    <Col sm={24} md={16}>
                          <Row gutter={12}>
                          <Col span={24} md={24} className="mb-3">
                              <div className="home-card-p">
                                  <div className="d-flex gap-3 align-items-start">
                                      <div className="logo-headding">
                                        <img width={125} height={125} src={componentgold} />
                                      </div>
                                      <div className="market-card-p">
                                          <h4>{lang("GOLD")}</h4>
                                          <CardBody className="market-card-body">
                                          <h6>$31.6676 <span>USD</span></h6>
                                          <p>+22.99%</p>
                                          </CardBody>
                                          
                                      </div>
                                  </div>
                                  <div className="notfy-bell">
                                              <img src={NottfyBell} /> 
                                          </div>
                                {JSON.stringify(customerGraphData) !== "{}" &&  <ReactApexChart
                                    options={customerGraphData.options}
                                    series={customerGraphData.series}
                                    type="line"
                                    height={250}
                                /> }
                              </div> 
                            </Col>
                            <Col sm={24} md={24} className="mb-3">
                              <Col span={24} className="p-0">
                                  <div className="table-headding">
                                  {lang("Overview")}
                                  </div>
                              </Col>
                              <div className="Overview-list">
                                  <ul>
                                    <li className="overview-list-items">
                                      <span>{lang("Vault")}</span>
                                      <h6>{lang("Loomis")}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Location")}</span>
                                      <h6>{lang("Switzerland")}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Premium")}</span>
                                      <h6>$0.00 {lang("per")} oz or obps</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Buy Price Per")} Oz</span>
                                      <h6>$24.91</h6>
                                    </li>

                                    <li className="overview-list-items">
                                      <span>{lang("Verified by")}</span>
                                      <h6>{lang("Top 5 Accounting firm")}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Insurance")}</span>
                                      <h6>{lang("Lloyds of London")}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Minimum Storage Fee")}</span>
                                      <h6>$5.00 {lang("per quater")}</h6>
                                    </li>
                                    <li className="overview-list-items">
                                      <span>{lang("Sell Price Per")} Oz</span>
                                      <h6>$24.45</h6>
                                    </li>

                                    <li className="overview-list-items">
                                      <span>Spread</span>
                                      <h6>$0.30% over spot</h6>
                                    </li>
                                    <li className="overview-list-items" style={{maxWidth:" fit-content"}} >
                                      <span>Storage</span>
                                      <h6>$0.30% per year billed quarterly</h6>
                                    </li>

                                    <li className="overview-list-items" style={{maxWidth:" fit-content"}}>
                                      <span>Allocation</span>
                                      <h6>Fully allocated and physically segregated</h6>
                                    </li>

                                  </ul>
                                  <p className="last-formate">U.S. Silver is a vaulted position of investment-grade physical gold, that is allocated, insured, audited, and stored with one of our premier vaulting partners. U.S. Gold is stored with APMEX, Brinks, Loomis international, or other COMEX approved vaults.</p>
                              </div>
                            </Col>
                            <Col  sm={24} md={24} className="mb-3">
                               <Card>
                               <Tabs className="buy-now-list" onTabClick={handleTabChange} >
                                      <TabPane  tab="History" key="Timesheet" className="transavtion">
                                          <div className="Transactions-header" >
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Bought Gold</h6>
                                                <p>24 hours ago <span>Complete</span></p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                              <span>0.5678 OZ</span>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Bought Gold</h6>
                                                <p>24 hours ago <span>Complete</span></p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                              <span>0.5678 OZ</span>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Bought Gold</h6>
                                                <p>24 hours ago <span>Complete</span></p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                              <span>0.5678 OZ</span>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Bought Gold</h6>
                                                <p>24 hours ago <span>Complete</span></p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                              <span>0.5678 OZ</span>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                      </TabPane>
                                      <TabPane tab="Recurring buys" key="LeaveRequest">
                                          <div className="add-new-items">
                                              <Link className="add-item-bt"><img src={AddBtn} />Add new</Link>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>$2 of Gold</h6>
                                                <p>On the 12th of each month</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>$2 of Gold</h6>
                                                <p>On the 12th of each month</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>$2 of Gold</h6>
                                                <p>On the 12th of each month</p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>$2 of Gold</h6>
                                                <p>On the 12th of each month</p>
                                              </div>
                                            </div>
                                          </div>
                                      </TabPane>
                                      <TabPane tab="Price alerts" key="Product" className="transavtion">
                                          <div className="add-new-items">
                                              <Link className="add-item-bt"><img src={AddBtn} />Add new</Link>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6> Gold</h6>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>Above</h6>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>SILVER</h6>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>Above</h6>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Platinum</h6>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>Above</h6>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={Iconspace} />
                                              <div className="transactions-list">
                                                <h6>Palladium</h6>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>$4.56 USD</h6>
                                            </div>
                                            <div className="Transactions-right ">
                                              <h6>Above</h6>
                                            </div>
                                            <div className="edit-icons">
                                              <Button type="button" className="edit-btns"><img src={Dotted} /></Button>
                                            </div>
                                          </div>
                                      </TabPane>
                                      <TabPane tab="News" key="News" className="transavtion">
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={News01} />
                                              <div className="transactions-list">
                                                <h6>Gold Market Stabilize</h6>
                                                <p>FX Empire</p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <span>4 hours ago</span>
                                            </div> 
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={News02} />
                                              <div className="transactions-list">
                                                <h6>Gold Market Stabilize</h6>
                                                <p>FX Empire</p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <span>4 hours ago</span>
                                            </div> 
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={News03} />
                                              <div className="transactions-list">
                                                <h6>Gold Market Stabilize</h6>
                                                <p>FX Empire</p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <span>4 hours ago</span>
                                            </div> 
                                          </div>
                                          <div className="Transactions-header">
                                            <div className="Transactions-text-headding">
                                              <img src={News04} />
                                              <div className="transactions-list">
                                                <h6>Gold Market Stabilize</h6>
                                                <p>FX Empire</p>
                                              </div>
                                            </div>
                                            <div className="Transactions-right ">
                                              <span>4 hours ago</span>
                                            </div> 
                                          </div>
                                      </TabPane>
                                  </Tabs>
                                </Card>     
                            </Col>
                            
                          </Row>
                        
                       </Col>
                        <Col sm={24} md={8}>
                            <div className="invest-card">
                                <Card  className="mb-3">
                                    <div className="list-items-btn">
                                        <span>Edit</span>
                                    </div>
                                    <div className="invest-prize">
                                        <h1>$5.00</h1>
                                        <span>0.00020671 OZ</span>
                                    </div>
                                    <div className="invest-list">
                                        <ul class="invest-list-item">
                                            <li class="list-item-iner">
                                                <span>Type</span>
                                                <p>Instant Buy</p>
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Payment method</span>
                                                <img src={Paymentmethod} />
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Available to withdraw</span>
                                                <p>24 hours</p>
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Metal price</span>
                                                <p>$41,942.02</p>
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Subtotal</span>
                                                <p>$8.67</p>
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Fee</span>
                                                <p>$0.99</p>
                                            </li>
                                            <li class="list-item-iner">
                                                <span>Platform fee</span>
                                                <p>$0.34</p>
                                            </li>
                                            <li class="list-item-iner total-count">
                                                <span>Total</span>
                                                <span>$5.00</span>
                                            </li>
                                        </ul>
                                        <div className="list-footer">
                                            Processed by mynt Trust Company, LLC. You agree to the <Link>terms of this transaction.</Link> Debit card transactions typically complete within 2 business days.
                                        </div>
                                    </div>
                                    <div className="signup-form">
                                        <Button className="ant-btn-default w-100 mt-4" type="button" > Confirm Buy </Button>
                                    </div>
                                </Card>
                                <Card>
                                <div className="metals-outer">
                                    <h6>Browse other metals</h6>
                                    <div className="metals-body">
                                        <img src={componentsilver} /> 
                                        <div className="metals-text">
                                            <h4>Silver</h4>
                                            <p>$2,210.77 USD</p>
                                            <span>-137.95 (-5.88%)</span>
                                        </div>
                                    </div> 
                                </div>
                            </Card>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>





        </section>
          <Footer className="footer">
          <Container>
            <div className="footer-first">
              <Row gutter={12}>
                <Col span={24} md={12} lg={5}>
                  <div className="footer-headding">
                    <h6>Company</h6>
                    <ul className="footer-subline">
                      <li>About mynt</li>
                      <li>Reviews</li>
                    </ul>
                  </div>
                </Col>
                <Col span={24} md={12} lg={5}>
                  <div className="footer-headding">
                    <h6>My Account</h6>
                    <ul className="footer-subline">
                      <li>Do not share my personal Inoformation</li>
                    </ul>
                  </div>
                </Col>
                <Col span={24} md={12} lg={5}>
                  <div className="footer-headding">
                    <h6>Learn</h6>
                    <ul className="footer-subline">
                      <li>How it Works</li>
                      <li>Pricing</li>
                      <li>Auto Invest</li>
                      <li>Deposits/Withdrawals</li>
                      <li>Physical Redemptions</li>
                      <li>Storage</li>
                    </ul>
                  </div>
                </Col>
                <Col span={24} md={12} lg={4}>
                  <div className="footer-headding">
                    <h6>Support</h6>
                    <ul className="footer-subline">
                      <li>Contact Us</li>
                      <li>FAQ</li>
                    </ul>
                  </div>
                </Col>
                <Col span={24} md={12} lg={5}>
                  <div className="footer-subscribe">
                    <h6>{lang("Subscribe")}</h6>
                    <div className="footer-subscribe-text">
                      <p>{lang("Subscribe to the mynt newsletter to receive timely market updates as well as info on sales and giveways.")}</p>
                    </div>
                    <div className="subscribe-box">
                      <Input placeholder="Enter your email" />
                      <Button className="subscribe-btn">Subscribe</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer-sec">
              <Row gutter={24}>
                <Col span={24} lg={12}>
                  <div className="footer-headding-second">
                    <h6>{lang("Major Payment Method Accepted")}</h6>
                    <div className="footer-subline-img">
                      <img src={payments01} />
                      <img src={payments02} />
                      <img src={payments04} />
                      <img src={payments03} />
                    </div>
                  </div>
                </Col>
                <Col span={24} lg={12}>
                  <div className="footer-headding-sp">
                    <h6>{lang("Download Our Free App")}</h6>
                    <div className="footer-second-img">
                      <img src={Playstore} />
                      <img src={iphone} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer-three">
              <Row gutter={12}>
                <Col md={24}>
                  <div className="footer-bottam">
                    <ul>
                      <li>© 2024 mynt</li>
                      <li>Privacy policy</li>
                      <li>Cookie Policy</li>
                      <li>Terms & Conditions</li>
                      <li>Accessibility</li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>

          </Container>
        </Footer>
        </>
    );
}



export default GoldReview;
