import { Form, Row, Col, Button, Input, Select, InputNumber } from "antd";
import "react-phone-input-2/lib/style.css";
import React, { useState, useContext, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Nav,
  Navbar,
  Accordion,
} from "react-bootstrap";

import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import PrivacyImg from "../../assets/images/privacy-img.png";
import lang from "../../helper/langHelper";
import AccountSideNav from "../../components/layout/AccountSideNav";  
import { useAppContext } from "../../context/AppContext";

function ContactUsIndex() {
  const { request } = useRequest();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const { isMobile } = useAppContext();
  const [form] = Form.useForm()

  return (
    <>
      <section className="main">
        <Header />
        <Container>
          <div className="home-outers">
            <Row gutter={24}>
           
              <Col span={24}>
                <div className="tital transactions-tital "></div>
              </Col>
              <Col span={24}>
                <div>
                  <Row
                    gutter={45}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    {/* <Col span={24} md={12}>
                      <div className="contact-img">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11589.574104075771!2d-7.373561775828642!3d43.43149647037278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd318dc6557eb4a9%3A0x81c6910d2f8984a6!2sMondo%C3%B1edo%2C%2027740%20Mondo%C3%B1edo%2C%20Lugo%2C%20Spain!5e0!3m2!1sen!2sin!4v1712917433473!5m2!1sen!2sin" width="100%" height="450"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </Col> */}
                    <Col span={24} md={12}>
                      <div className="about-mynt">
                        <Form layout="vertical" className="contact-detail" form={form}>
                          <div className="contact-headding">
                            <h4 className="mb-3">{lang("Contact Us")}</h4>
                          </div>
                          <Row gutter={12}>
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form"
                                name="firstname"
                                cover={{ md: 12 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Please input!"),
                                  },
                                ]}
                              >
                                <Input placeholder={lang("First Name")} />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form"
                                name="lastname"
                                cover={{ md: 12 }}
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Please input!"),
                                  },
                                ]}
                              >
                                <Input placeholder={lang("Last Name")} />
                              </Form.Item>
                            </Col>
                            {/* <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form" 
                                name="Select"
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input!',
                                  },
                                ]}
                              >
                                <Select placeholder="City" />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form" 
                                name="Select"
                                cover={{ md: 12 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input!',
                                  },
                                ]}
                              >
                                <Select placeholder="State" />
                              </Form.Item>
                            </Col> */}
                            <Col span={24} md={12}>
                              <Form.Item
                                name="InputNumber"
                                cover={{ md: 12 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Please input!"),
                                  },
                                ]}
                              >
                                <InputNumber
                                  className="number-inn"
                                  placeholder={lang("Mobile Number")}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                              <Form.Item
                                className="contact-form"
                                name="email"
                                cover={{ md: 12 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Please input!"),
                                  },
                                ]}
                              >
                                <Input
                                  className="place-inne"
                                  placeholder={lang("Email")}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24} md={24}>
                              <Form.Item
                                className="contact-form"
                                name="TextArea"
                                cover={{ md: 24 }}
                                colProps={{ sm: 24, span: 24 }}
                                rules={[
                                  {
                                    required: true,
                                    message: lang("Please input!"),
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  placeholder={lang("Your Message")}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item>
                            <Button form="create" type="primary" htmlType="submit" className="ant-btn-default" onClick={()=>form.resetFields()}>
                              {lang("Submit")}
                            </Button>
                          </Form.Item>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default ContactUsIndex;
