import {
  Row,
  Col,
  Button,
  Card,
  Form,
  Input,
  Select,
  Modal,
} from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import OwlCarousel from 'react-owl-carousel3';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import $ from 'jquery'
import Main from "../../components/layout/Main";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {VerticalAlignBottomOutlined,VerticalAlignTopOutlined,SwapOutlined} from "@ant-design/icons"
import { CardBody, CardFooter, CardTitle, Container, ModalBody, Dropdown, ModalTitle,Nav , Navbar, } from "react-bootstrap";
import Logo from '../../assets/images/logo-white.svg'
import Usd from '../../assets/images/currency-usd.png'
import Exchange from '../../assets/images/exchange.png'
import User from '../../assets/images/user-bold.png'
import Nottfy from '../../assets/images/bell-outline.png'
import Begin01 from '../../assets/images/begin01.jpg'
import Begin02 from '../../assets/images/begin01.jpg'
import Begin03 from '../../assets/images/begin01.jpg'
import { Footer } from "antd/lib/layout/layout";
import Rightcircal from '../../assets/images/right-circal.png'
import payments01 from '../../assets/images/Possiblepayments01.svg'
import payments02 from '../../assets/images/Possible-payments.svg'
import payments03 from '../../assets/images/Possible-payments 02.svg'
import payments04 from '../../assets/images/Possible-payments 03.svg'
import Playstore from '../../assets/images/play-app-store.svg'
import iphone from '../../assets/images/iphone-app-store.svg'
import Toperrow from '../../assets/images/top-errow.svg'
import Dawnerrow from '../../assets/images/red-dawn.svg' 
import Myntcard from '../../assets/images/myntcard.jpg'
import Paymentmethod from '../../assets/images/payment-method.svg'
import GoldPrime from '../../assets/images/gold-prime.png'

function HomeReviewOrder() {
  const { request } = useRequest()
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)

  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const owlCarouselOptions = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.3,
      },
      600: {
        items: 2.3,
      },
      1000: {
        items: 3,
      },
    },
  };

  const owlCarouselOptions02 = {
    items: 3,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 1.1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <section className="main">
        <div className="categrory-header">
          <ul>
            <li className="categrory-nav">
              <p>Live Spot Prices:</p>
            </li>
            <li className="categrory-nav">
              <h6>Gold:</h6>
              <h6> $2,045.42</h6>
              <span><img src={Toperrow} />$0.02</span>
            </li>
            <li className="categrory-nav">
              <h6>Silver:</h6>
              <h6> $2,045.42</h6>
              <span> <img src={Toperrow} />$0.02</span>
            </li>
            <li className="categrory-nav">
              <h6>Platinum:</h6>
              <h6> $2,045.42</h6>
              <span><img src={Toperrow} />$0.02</span>
            </li>
            <li className="categrory-nav">
              <h6>Palladium:</h6>
              <h6> $2,045.42</h6>
              <span><img src={Toperrow} />$0.02</span>
            </li>
          </ul>
        </div>
        <header>
          <Navbar expand="lg" className="p-0">
            <Navbar.Brand href="#"><img src={Logo} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto   nav-items-name" >
                <Nav.Link href="" className="nav-menu active">Home</Nav.Link>
                <Nav.Link href="" className="nav-menu">Market</Nav.Link>
                <Nav.Link href="" className="nav-menu"> Portfolio </Nav.Link>
                <Nav.Link href="" className="nav-menu"> FAQ </Nav.Link>
              </Nav>
              <div className="btn-header">
                <Dropdown>
                    <Dropdown.Toggle className="header-dropdawn" variant="success" id="dropdown-basic">
                       <Button className="ant-btn-header" type="button" ><img src={Usd} />   USD  </Button>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdawn-topbar">
                        <div className="submenu-dropdawn">
                          <ul>
                            <li>
                              <div className="topsearchbar"> 
                                <h5>Choose default currency</h5>
                              </div>
                            </li>
                            <li className="menu-body">
                              <Link>USD</Link>
                            </li>
                            <li className="menu-body">
                              <Link>AUD</Link>
                            </li>
                            <li className="menu-body">
                              <Link>CAD</Link>
                            </li>
                            <li className="menu-body">
                              <Link>EUR</Link>
                            </li>
                            <li className="menu-body">
                              <Link>GPB</Link>
                            </li>
                            <li className="menu-body">
                              <Link>HKD</Link>
                            </li>
                            <li className="menu-body">
                              <Link>SGD</Link>
                            </li>
                          </ul>
                        </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown>
                    <Dropdown.Toggle className="header-dropdawn" variant="success" id="dropdown-basic">
                      <Button className="ant-btn-header" type="button"><img src={Exchange} />  Transfer  </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdawn-topbar">
                          <div className="submenu-dropdawn">
                            <ul>
                              <li>
                                <div className="topsearchbar"> 
                                  <h5>Transfer</h5>
                                </div>
                              </li>
                              <li className="menu-body">
                                <Link><VerticalAlignBottomOutlined />Deposit cash</Link>
                              </li>
                              <li className="menu-body">
                                <Link><VerticalAlignTopOutlined /> Withdraw cash</Link>
                              </li>
                              <li>
                                <div className="topsearchbar"> 
                                  <h5>Transfer</h5>
                                </div>
                              </li>
                              <li className="menu-body">
                                <Link> <SwapOutlined /> Move between accounts</Link>
                              </li>
                            
                            </ul>
                          </div>
                      </Dropdown.Menu>
                    </Dropdown>
               
                <Button className="ant-btn-header" type="button" to="a"><img src={User} />  Account  </Button>
                <Button className="ant-btn-header" type="button"><img src={Nottfy} /> </Button>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </header>

        <Container>
          <div className="account-header">
            <ul>
              <li className="account-nav">
                <p>Account Value:</p>
                <h6>$6,903.64</h6>
              </li>
              <li className="account-nav">
                <p>Total Performance:</p>
                <span>$0.02 <img src={Dawnerrow} /></span>
              </li>
              <li className="account-nav">
                <p>Cash Balance:</p>
                <h6>$6,903.64</h6>
              </li>
              <li className="account-nav">
                <p>Metal Holding:</p>
                <h6>$6,903.64</h6>
              </li>
            </ul>
          </div>
          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24} lg={16}>
              <Row gutter={12}>
                  <Col span={24} md={24} lg={12} className="mb-5">
                    <div className="home-card-p">
                      <Card >
                        <CardBody className="home-card-body">
                          <h4>Get started today with as little as $10.</h4>
                        </CardBody>
                        <Button className="ant-btn-default white-btn" type="button" >
                        Start tradings
                        </Button>
                      </Card>
                    </div>
                  </Col>
                  <Col sm={24} md={24} lg={12} className="mb-5">
                    <div className="home-card-p">
                      <Card >
                        <CardBody className="home-card-body">
                          <h4>Set Up Auto Invest</h4>
                          <p>Build your portfolio with automatic investments.</p>
                        </CardBody>
                        <Button className="ant-btn-default white-btn" type="button" >
                          Start Now
                        </Button>
                      </Card>
                    </div>
                  </Col>
                </Row>
                
                <Row gutter={12}>
                  <Col span={24}>
                    <div className="tital transactions-tital ">
                      <h4 className="m-0">Recent Transactions</h4>
                      <Link>View all
                      </Link>
                    </div>
                  </Col>
                  <Col span={24}>
                    <Card className="transaction-section">
                      <div className="transaction-history">
                        <ul>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span>+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Sold Silver</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Platinum</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Silver</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Platinum</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                          <li>
                            <div className="transaction-history-left">
                              <h6>Bought Gold</h6>
                              <span>Dec 1, 2023 6:07 pm</span>
                            </div>
                            <div className="transaction-history-right">
                              <h4>$2,345.54</h4>
                              <span className="text-danger">+2,345(OZ)</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row gutter={12} className="mt-5">
                  <Col span={24}>
                    <div className="tital">
                      <h4>Resources for you</h4>
                    </div>
                  </Col>
                 
                    <Col span={24} md={8}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin01}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24} md={8}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin02}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24} md={8}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resources-card-body">
                            <img src={Begin03}></img>
                            <h5>Begin with Metals</h5>
                            <p>Create a strong foundation by understanding the most well-known metals on the market.</p>
                          </CardBody>
                        </Card>
                      </div>
                    </Col> 

                </Row>
                <Row gutter={12} className="mt-5">
                  <Col span={24}>
                    <div className="tital">
                      <h4>Recent News</h4>
                    </div>
                  </Col>
                  <OwlCarousel  {...owlCarouselOptions02}
                    className="owl-theme owl-resources"
                    loop
                    autoplay={false}
                    dots={false}
                    nav={true}
                    margin={8}
                  >
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resent-gold-card-body">
                            <div className="resent-gold">
                              <div className="resent-img-gold">
                                <img src={GoldPrime}></img>
                              </div>
                              <div className="resent-text-gold">
                                <h5>Gold, silver prices unchanged; yellow metal trading at Rs 63,050/10g</h5>
                                <p>The price of 24-carat gold remained unchanged in early trade on Monday, with ten grams of the precious metal selling at Rs 63,050, according to the GoodReturns website. The price of silver was also unchanged, with one kilogram of the precious metal selling at Rs 75,500.</p>
                              </div>
                            </div>
                            <Button className="resent-btn mt-3">Read more</Button>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="resources-card-p">
                        <Card>
                          <CardBody className="resent-gold-card-body">
                            <div className="resent-gold">
                              <div className="resent-img-gold">
                                <img src={GoldPrime}></img>
                              </div>
                              <div className="resent-text-gold">
                                <h5>Gold, silver prices unchanged; yellow metal trading at Rs 63,050/10g</h5>
                                <p>The price of 24-carat gold remained unchanged in early trade on Monday, with ten grams of the precious metal selling at Rs 63,050, according to the GoodReturns website. The price of silver was also unchanged, with one kilogram of the precious metal selling at Rs 75,500.</p>
                              </div>
                            </div>
                            <Button className="resent-btn mt-3">Read more</Button>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>
                  </OwlCarousel>

                </Row>
              </Col>
              <Col span={24} lg={8}>
                <div className="invest-card">
                  <Card>
                    <div className="list-items-btn">
                      <span>Edit</span>
                    </div>
                    <div className="invest-prize">
                      <h1>$10.00</h1>
                      <span>0.00020671 OZ</span>
                    </div>
                    <div className="invest-list">
                      <ul class="invest-list-item">
                        <li class="list-item-iner">
                          <span>Type</span>
                          <p>Instant Buy</p>
                        </li>
                        <li class="list-item-iner">
                          <span>Payment method</span>
                          <img src={Paymentmethod} />
                        </li>
                        <li class="list-item-iner">
                          <span>Available to withdraw</span>
                          <p>24 hours</p>
                        </li>
                        <li class="list-item-iner">
                          <span>Metal price</span>
                          <p>$41,942.02</p>
                        </li>
                        <li class="list-item-iner">
                          <span>Subtotal</span>
                          <p>$8.67</p>
                        </li>
                        <li class="list-item-iner">
                          <span>Fee</span>
                          <p>$0.99</p>
                        </li>
                        <li class="list-item-iner">
                          <span>Platform fee</span>
                          <p>Instant Buy</p>
                        </li>
                        <li class="list-item-iner total-count">
                          <span>Total</span>
                          <span>$10.00</span>
                        </li>
                      </ul>
                      <div className="list-footer">
                        Processed by mynt Trust Company, LLC. You agree to the <Link>terms of this transaction.</Link> Debit card transactions typically complete within 2 business days.
                      </div>
                    </div>
                    <div className="signup-form pb-0">
                      <Button className="ant-btn-default w-100 mt-4 " type="button" onClick={showModal}> Confirm Buy </Button>
                    </div>
                  </Card>
                </div>
                <Card className="invite-frnd mt-3">
                  <CardBody className="home-card-body">
                    <h4>Tell your friends about mynt</h4>
                    <p>Get $10 in metal when you invite a friend and they trade $100 USD.</p>
                  </CardBody>
                  <CardFooter>
                    <div className="share-link-now">
                      <span>https://www.mynt.com/share</span>
                      <Button className="ant-btn-default" type="button" >Copy</Button>
                    </div>
                  </CardFooter>
                </Card>
                <Card className="credit-card-box">
                  <CardTitle>The mynt Credit CardTM</CardTitle>
                  <CardBody>
                    <img className="card-imagas" src={Myntcard} />
                    <p className="card-line-text">mynt Foundation Derivatives is currently not available in your region. Sign up to get notified when Derivatives become available in US.</p>
                  </CardBody>
                  <CardFooter>
                    <Button className="ant-btn-default mt-3" type="button">See if you pre-qualify</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>

        <Modal width={670} className="modal01" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}  footer={false}>

          <ModalBody>
            <div className="modal-sucses-pass">
              <img src={Rightcircal}></img>
              <h4>Your buy order has been placed.</h4>
              <p>You purchased <strong> 0.00020634</strong> OZ for <strong> &10.00  USD.</strong> </p>
              <p>You can review your transactions in  <Link>your portfolio.</Link> </p>
            </div>
            <div className="sucses-pass-footer">
              <Button className="ant-btn-default" type="button" >Done</Button>
            </div>

          </ModalBody>

        </Modal>

      </section>
      <Footer className="footer">
        <Container>
          <div className="footer-first">
            <Row gutter={12}>
              <Col span={24} md={12} lg={4}>
                <div className="footer-headding">
                  <h6>Company</h6>
                  <ul className="footer-subline">
                    <li>About mynt</li>
                    <li>Reviews</li>
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={5}>
                <div className="footer-headding">
                  <h6>My Account</h6>
                  <ul className="footer-subline">
                    <li>Do not share my personal Inoformation</li>
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={5}>
                <div className="footer-headding">
                  <h6>Learn</h6>
                  <ul className="footer-subline">
                    <li>How it Works</li>
                    <li>Pricing</li>
                    <li>Auto Invest</li>
                    <li>Deposits/Withdrawals</li>
                    <li>Physical Redemptions</li>
                    <li>Storage</li>
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={4}>
                <div className="footer-headding">
                  <h6>Support</h6>
                  <ul className="footer-subline">
                    <li>Contact Us</li>
                    <li>FAQ</li>
                  </ul>
                </div>
              </Col>
              <Col span={24} md={12} lg={6}>
                <div className="footer-subscribe">
                  <h6>Subscribe</h6>
                  <div className="footer-subscribe-text">
                    <p>Subscribe to the mynt newsletter to receive timely market updates as well as info on sales and giveways.</p>
                  </div>
                  <div className="subscribe-box">
                    <Input placeholder="Enter your email" />
                    <Button className="subscribe-btn">Subscribe</Button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-sec">
            <Row gutter={24}>
              <Col span={24} lg={12}>
                <div className="footer-headding-second">
                  <h6>Major Payment Method Accepted</h6>
                  <div className="footer-subline-img">
                    <img src={payments01} />
                    <img src={payments02} />
                    <img src={payments04} />
                    <img src={payments03} />
                  </div>
                </div>
              </Col>
              <Col span={24} lg={12}>
                <div className="footer-headding-sp">
                  <h6>Download Our Free App</h6>
                  <div className="footer-second-img">
                    <img src={iphone} />
                    <img src={Playstore} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footer-three">
            <Row gutter={12}>
              <Col md={24}>
                <div className="footer-bottam">
                  <ul>
                    <li>© 2024 mynt</li>
                    <li>Privacy policy</li>
                    <li>Cookie Policy</li>
                    <li>Terms & Conditions</li>
                    <li>Accessibility</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

        </Container>
      </Footer>

    </>

  );
}



export default HomeReviewOrder;
