import {
  Row,
  Col,
  Button,
  Input,
} from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { CardBody, CardFooter, CardTitle, Container, Nav, Navbar, Accordion } from "react-bootstrap";

import Header from "../../components/layout/Header"
import Footer from "../../components/layout/Footer"
import PrivacyImg from '../../assets/images/privacy-img.png';

function Accessibility() {
  const { request } = useRequest()
  const [data, setData] = useState('');
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const getResources = () => {
    setLoading(true)
    request({
      url: `/app/content/get-content/privacy-policy`,
      method: "GET",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "privacy-policy");
        setLoading(false)
        if (data.data.length) {

          setData(data.data[0]);
        }
      },
      onError: (err) => {
        setLoading(false)
      }
    });
  };

  useEffect(() => {
    getResources()
  }, [])


  return (
    <>
      <section className="main">
        <Header />
        <Container>

          <div className="home-outers">
            <Row gutter={24}>
              <Col span={24}>
                <div className="tital transactions-tital ">
                  <h4 className="m-0">Accessibility</h4>
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Row gutter={45}>
                    <Col md={9}>
                      <div className="about-img">
                        <img src={PrivacyImg} />
                      </div>
                    </Col>
                    <Col md={15}>
                      <div className="about-mynt">
                        {/* <h6>Mynt is about giving you greater choice and the opportunity to achieve your financial dreams.</h6> */}
                        <p>My Auto Store recently received the 2023 Certified Automotive Recycler (CAR) and Recall certification from the Automotive Recyclers Association (ARA), who is the voice of the professional automotive recycling industry. The certification allows more than airbag sales, it also provides a guideline of compliance and excellence for businesses to follow a strict set of US standards through the CAR program.</p>
                        <p>My Auto Store is the latest business certified to sell recycled air bags and we are among a small group of businesses certified to sell airbags in the United States this year. As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                        <p>This is the first time My Auto Store applied for the prestigious CAR certification, and we received an exceptionally high score of 98.57% that is remarkable for a newcomer.</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                        <p>This is the first time My Auto Store applied for the prestigious CAR certification, and we received an exceptionally high score of 98.57% that is remarkable for a newcomer.</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the pric</p>
                        <p>As an authorized recycled OEM non-deployed vehicle airbag reseller, customers can legally purchase airbags on our website. Our airbags are a good alternative to ordering a new replacement for a fraction of the price.</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

      </section>
      <Footer />
    </>
  );
}



export default Accessibility;
