let appMode = process.env.REACT_APP_ENV;
export const BASE_URL = 'http://153.92.4.13:9211/api'
let ASSET_URL = "https://inventcolabs.s3.amazonaws.com/";
let URL;
console.log("APPMode", appMode);

if (appMode === "development") {
  URL = "https://myntgold.com/api/";
  // URL = "http://localhost:9211/api";
} else {
  URL = "https://myntgold.com/api/";
  // URL = "http://localhost:9211/api";
}

let apiPath = {
  baseURL: URL,
  assetURL: ASSET_URL,

  // Auth APIs for User Web Login sign-up so on edit-profile
  signUp: "app/auth/signUp",
  profile: "app/auth/get-profile",
  editProfile: "app/auth/edit-profile",
  sendOtp: "app/auth/sendOtp",
  login: "app/auth/login",
  forgotPassword: "app/auth/forget-password",
  resetPassword: "app/auth/reset-password",
  verifyOtp: "app/auth/verify-otp",
  emailVerify: "app/auth/verify-email",
  resendOtp: "app/auth/resend-otp",
  contactUs: "app/auth/contact-us",
  deleteProfile: "app/auth/delete-profile",
  editNotificationSetting: "app/auth/edit-notification-settings",
};

export default apiPath;
