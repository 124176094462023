import {
    Form,
    Row,
    Col,
    Button,
    Input,
    Select,
    InputNumber,

} from "antd";
import 'react-phone-input-2/lib/style.css'
import React, { useState, useContext, useEffect } from "react";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import useRequest from "../../hooks/useRequest";
import { Link, useNavigate,useLocation } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { CardBody, CardFooter, CardTitle, Container, Nav, Navbar, Accordion } from "react-bootstrap";

import Header from "../../components/layout/Header"
import Footer from "../../components/layout/Footer"
import RightIcon from "../../assets/images/right-errow.png";
import PrivacyImg from '../../assets/images/privacy-img.png';
import AccountSideNav from "../../components/layout/AccountSideNav";
import lang from "../../helper/langHelper";
import { BackArrow } from "./Index";
import { useAppContext } from "../../context/AppContext";

function Index() {
    const location = useLocation();
    const { request } = useRequest()
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate()
    const { isMobile } = useAppContext();


    return (
        <>
            <section className="main">
                <Header /> 

                    <div className= "account-main pt-1">
                        <Row gutter={24}>
                        <div className="mobile-menu-headding">
                        {!isMobile ? <AccountSideNav /> :
                            <BackArrow />
                            }
                             <div className="table-headding"> Support Center </div>
                            </div>
                            <Col span={24}>
                           
                                <div className="policies-headding"></div>
                                <div className=" ">
                                    <div className="inner-side-menu">
                                        <ul>
                                            <li
                                                onClick={() => navigate("/contact")}
                                                className={`nav-items inner-mobile-side ${location.pathname === "/contact" ? "active" : ""
                                                    }`}
                                            >
                                                <div className="accont-details">
                                                    <div className="accounts-detail-list">
                                                        <Link to="/contact"> {lang("Contact us")}</Link> 
                                                    </div>
                                                    <div className="errow-icon">
                                                        <img width={10} src={RightIcon} />
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </div> 
            </section> 
        </>
    );
}



export default Index;
