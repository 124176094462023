import { Col, Popover, Row, Typography } from "antd";
import React, { useContext, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";

import { Link, useNavigate } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import FacebookIcon from "../../assets/images/icon/facebook.svg";
import MailIcon from "../../assets/images/icon/mail-icon.svg";
import WhatsappIcon from "../../assets/images/icon/whatsapp-icon.svg";
import InviteImgDark from "../../assets/images/invitefriends-dark.svg";
import InviteImg from "../../assets/images/invitefriends.svg";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function InviteFriends() {
  const { request } = useRequest();
  const { Title } = Typography;
  const { userProfile } = useContext(AuthContext);
  const { isMobile } = useAppContext();

  const navigate = useNavigate();

  const [isCopied, setIsCopied] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleHoverChange = (open) => {
    setHovered(open);
    if (!open) return setIsCopied(false);
  };

  const copyContent = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (err) {
      console.error(lang("Failed to copy: "), err);
    }
  };

  const shareOnFacebook = () => {
    window.open(
      "https://facebook.com/share.php?u=" + userProfile?.refer_code,
      "_blank",
    );
  };

  const shareOnWhatsApp = () => {
    window.open(
      "https://web.whatsapp.com/send?text=" + userProfile?.refer_code,
      "_blank",
    );
  };

  const shareOnEmail = () => {
    window.open(
      "https://mail.google.com/mail/u/0/" + userProfile?.refer_code,
      "_blank",
    );
  };

  return (
    <>
      <section className="main">
        <Header />
        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15}  className="mx-auto">
              <div className="account-main">
                <div className="table-headding">{lang("Invite Friends")}</div>
                <Row gutter={32}>
                  <Col sm={24} lg={12}>
                    <div className="invite-outer">
                      <div className="invite-left-outer">
                        <span>{lang("Share your code with your friends")}</span>
                        <div className="refercode">
                          <Popover
                            content={() => (
                              <h6 style={{color : "black"}}>{isCopied ? lang(`copied`) : lang(`Click to copy`)}</h6>
                            )}
                            trigger="hover"
                            open={hovered}
                            onOpenChange={handleHoverChange}
                          >
                            <Link
                              onClick={() =>
                                copyContent(userProfile?.refer_code)
                              }
                            >
                              {userProfile?.refer_code ?? "REFERCODE"}
                            </Link>
                          </Popover>
                        </div>
                      </div>

                      <div className="invite-left-outer02">
                        <h6>{lang("Invite by")}</h6>
                        <div className="invite-social">
                          <div className="social-items-in">
                            <Link onClick={shareOnFacebook}>
                              <img src={FacebookIcon} />
                            </Link>
                          </div>
                          <div className="social-items-in">
                            <Link onClick={shareOnEmail}>
                              <img className="main-icon" src={MailIcon} />
                            </Link>
                          </div>
                          <div className="social-items-in">
                            <Link onClick={shareOnWhatsApp}>
                              <img src={WhatsappIcon} />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={24} lg={12}>
                    <div className="invite-right-outer">
                      <img className="dark-none" src={InviteImg} />
                      <img className="dark-block" src={InviteImgDark} />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default InviteFriends;
