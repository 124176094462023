import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Modal,
  Select,
  Checkbox,
} from "antd";
import Logo from "../../assets/images/logo-black.svg";
import LogoWhite from "../../assets/images/logo-white.svg";
import useRequest from "../../hooks/useRequest";
import apiUrl from "../../constants/api_urls";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import Loader from "../../components/Loader";
import { useAppContext } from "../../context/AppContext";
import FlagIndia from "../../assets/images/india-flag.svg";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const { Title } = Typography;
const { Content } = Layout;

const defaultCountry = {
  country: {
    name: "United States",
    native: "United States",
    iso2: "US",
    emojiU: "U+1F1FA U+1F1F8",
    emoji: "🇺🇸",
    _id: "646b2e0f46865f1f65565389",
  },
  language: "en",
};

const languageObject = {
  en: "English",
  es: "Española",
};

const onChange = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const onSearch = (value) => {
  console.log("search:", value);
};

// Filter `option.label` match the user type `input`
const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const SignUp = () => {
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { setEmail, setPassword } = useAppContext();

  const api = {
    sendOtp: apiUrl.sendOtp,
    signUp: apiUrl.signUp,
  };

  const [mobileNumber, setMobileNumber] = useState({
    phoneNumber: "",
    countryCode: "",
  });

  const [loading, setLoading] = useState(false);

  const [accountType, setAccountType] = useState("Individual");

  const [businessLocation, setBusinessLocation] = useState(defaultCountry);

  const [userLocation, setUserLocation] = useState(defaultCountry);

  const { request } = useRequest();

  const onFinish = (values) => {
    console.log(values, "hfjhdkghkhdgkd");
    const { email, password, lastName, familyName, name } = values;

    let payload = {
      type: "sign-up",
      email,
      password,
      lastName,
      familyName,
      name,
      userCountry: userLocation.country.name,
      userLanguage: userLocation.language,
    };

    if (accountType == "Business") {
      payload = {
        ...payload,
        ...mobileNumber,
        userBusinessCountry: businessLocation.country.name,
        userBusinessLanguage: businessLocation.language,
      };
    }

    setEmail(email);
    setPassword(password);
    console.log(payload, "payload");
    register(payload);
  };

  const register = (payload) => {
    console.log("register");
    request({
      url: api.sendOtp,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast("Verification Email send  on your email", Severty.SUCCESS);

          // setIsLoggedIn(true);
          setTimeout(() => navigate("/email-sent"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleChange = (value, data) => {
    var country_code = data.dialCode;
    setMobileNumber({
      countryCode: country_code,
      phoneNumber: value.slice(data.dialCode.length),
    });
  };

  const onFinishFailed = () => {};

  const [locationModal, showLocationModal] = useState(false);
  const [businessLocationModal, showBusinessLocationModal] = useState(false);

  useEffect(() => {
    console.log(accountType, "accountType");
  }, [accountType]);

  const handleCancel = () => {
    showLocationModal(false);
  };

  return (
    <>
      {loading && <Loader />}

      <Layout className="layout-default layout-signin">
        <Content className="signin d-none d-md-block">
          <Row className=" align-items-center">
            <Col span={24} sm={6} lg={6} md={6}>
              <div className="signup-logo d-dark-none">
                <img src={Logo} alt="" onClick={() => navigate("/")} />
              </div>
              <div className="signup-logo d-dark-block">
                <img
                  src={LogoWhite}
                  alt=""
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Col>

            <Col span={24} sm={18} lg={18} md={18}> 
              <div className="signup-right">
                <Link className="sign-in-link-type" type="button">
                  Already have a mynt account?
                </Link>
                <Button
                  className="sign-in-btn"
                  type="button"
                  onClick={() => navigate("/login")}
                  // htmlType="submit"
                >
                  Sign in
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        

        <div className="signin-box mt-5 sign-up-main">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="signup-form">
                <Title className="tital-text-sign mb-15">
                  Let’s get you started with a Mynt account
                </Title>

                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item className="username-form " label="Account type">
                    <div className="account-btn-header">
                      <Button
                        onClick={() => setAccountType("Individual")}
                        className={`${
                          accountType != "Individual" ? "active" : ""
                        }`}
                      >
                        <div className="individual-account">
                          <span className="user-dp-img" color="#010304">
                            <svg
                              aria-hidden="true"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="currentcolor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12ZM10 14C6.68629 14 4 16.6863 4 20V22H20V20C20 16.6863 17.3137 14 14 14H10Z"
                              ></path>
                            </svg>
                          </span>
                          <div className="individuel-text">
                            <h4>Individual</h4>
                            <p>An individual account for personal use</p>
                          </div>
                        </div>
                      </Button>
                      <Button
                        onClick={() => setAccountType("Business")}
                        className={`${
                          accountType != "Business" ? "active" : ""
                        }`}
                      >
                        <div className="individual-account">
                          <span className="user-dp-img" color="#010304">
                            <svg
                              aria-hidden="true"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              fill="currentcolor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.47 4.152C11.7943 3.94933 12.2058 3.94933 12.53 4.152L20.53 9.152C20.9083 9.38844 21.0843 9.84686 20.9613 10.2757C20.8383 10.7045 20.4461 11 20 11H4.00002C3.55391 11 3.16176 10.7045 3.03877 10.2757C2.91579 9.84686 3.09172 9.38844 3.47002 9.152L11.47 4.152ZM5.99998 17.9999H3.99998V19.9999H6.99998H12H17H20V17.9999H18V11.9999H16V17.9999H13V11.9999H11V17.9999H7.99998V11.9999H5.99998V17.9999Z"
                              ></path>
                            </svg>
                          </span>
                          <div className="individuel-text">
                            <h4>Business</h4>
                            <p>
                              Create a business account on behalf of a company,
                              trust, or institution
                            </p>
                          </div>
                        </div>
                      </Button>
                    </div>
                  </Form.Item>

                  {accountType == "Business" && (
                    <>
                      <div className="info-body-text">
                        <h2>Business info</h2>
                        <p>
                          Tell us a little about the company, trust, or
                          institution you intend on creating an account for.
                        </p>
                        <Link>Joining an existing business account?</Link>
                      </div>

                      <Form.Item
                        className="username-form"
                        label="Business location"
                      >
                        <div className="code-typediv">
                          <Button
                            className="cuntry-code"
                            // onClick={() => showBusinessLocationModal(true)}
                          >
                            <div className="individual-account">
                              <span className="flag-imgs">
                                {/* <img src={FlagIndia} /> */}
                                {businessLocation.country.emoji}
                              </span>
                              <div className="individuel-text">
                                <h6>{businessLocation.country.name}</h6>
                                <p>
                                  {languageObject[businessLocation.language]}
                                </p>
                              </div>
                              {/* <div className="language-changes">
                                <Button className="language-btns">
                                  Change
                                </Button>
                              </div> */}
                            </div>
                          </Button>
                        </div>
                      </Form.Item>

                      <div className="info-body-text">
                        <h2>Admin account</h2>
                        <p>
                          Please provide your information to create the
                          administrator account for your business.
                        </p>
                      </div>
                    </>
                  )}

                  <Row gutter={[16, 16]} className="mb-3">
                    <Col span={24} md={24}>
                      <Form.Item
                        className="username-form"
                        // label="First name"
                        name="name"
                        rules={[
                          {
                            max: 255,
                            message: "First name not more then 255 characters!",
                          },
                          {
                            required: true,
                            message: "Please enter First name!",
                          },
                        ]}
                      >
                        <Input
                          className="form-of-type"
                          placeholder="First name"
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col span={24} md={12}>
                      <Form.Item
                        className="username-form"
                        label="Middle name (optional)"
                        name="familyName"
                      >
                        <Input className="form-of-type" placeholder="Middle name" />
                      </Form.Item>
                    </Col> */}
                  </Row>

                  <Form.Item
                    className="username-form"
                    // label="Last name"
                    name="lastName"
                    rules={[
                      {
                        max: 255,
                        message: "Last name not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter Last name!",
                      },
                    ]}
                  >
                    <Input className="form-of-type" placeholder="Last name" />
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    // label="Email ID"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        max: 255,
                        message: "Email address not more then 255 characters!",
                      },
                      {
                        required: true,
                        message: "Please enter email!",
                      },
                    ]}
                  >
                    <Input
                      autoComplete="off"
                      className="form-of-type"
                      placeholder="Email Address"
                    />
                  </Form.Item>

                  {accountType == "Business" && (
                    <div className="user-dirct mb-3">
                      <Form.Item
                        className="username-form"
                        // label={"Mobile Number"}
                        name="mobile"
                        rules={[
                          {
                            required: true,
                            validator: (rule, value) => {
                              if (!value) {
                                return Promise.reject(
                                  "Please enter phone number"
                                );
                              }
                              if (!/^\d{8,15}$/.test(value)) {
                                return Promise.reject(
                                  "Phone number must be between 8 and 12 digits"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <PhoneInput
                          className="number-type"
                          inputProps={{
                            name: "mobile",
                            required: true,
                            autoFocus: false,
                            autoFormat: false,
                            autoComplete: "off",
                          }}
                          isValid={(value, country) => {
                            if (value.match(/1234/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else if (value.match(/1234/)) {
                              return (
                                "Invalid value: " + value + ", " + country.name
                              );
                            } else {
                              return true;
                            }
                          }}
                          country={"il"}
                          preferredCountries={["ps", "il"]}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  )}

                  <Form.Item
                    className="username-form"
                    // label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password!",
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="off"
                      onCut={(e) => e.preventDefault()}
                      onCopy={(e) => e.preventDefault()}
                      onPaste={(e) => e.preventDefault()}
                      className="form-of-type"
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    initialValue={"en"}
                    // label="Language"
                    name={"language"}
                    rules={[
                      {
                        required: true,
                        message: "Please Select language ",
                      },
                    ]}
                  >
                    <Select
                      className="form-of-type"
                      // showSearch
                      placeholder="Select a Language"
                      optionFilterProp="children"
                      // onSearch={onSearch}
                      // filterOption={filterOption}
                      options={[
                        {
                          value: "en",
                          label: "English",
                        },
                        {
                          value: "es",
                          label: "Española",
                        },
                      ]}
                      onChange={(value) => {
                        setUserLocation((prev) => ({
                          ...prev,
                          language: value,
                        }));
                      }}
                    />
                  </Form.Item>

                  {/* <Form.Item
                    className="username-form"
                    // label="Location and language"
                  >
                    <div className="code-typediv">
                      <Button
                        className="cuntry-code"
                        // onClick={showModal}
                      >
                        <div className="individual-account">
                          <span className="flag-imgs">
                            {/* <img src={FlagIndia} /> *}
                            {userLocation.country.emoji}
                          </span>
                          <div className="individuel-text">
                            <h6> {userLocation.country.name}</h6>
                            <p>{languageObject[userLocation.language]}</p>
                          </div>
                          {/* <div className="language-changes">
                            <Button className="language-btns">Change</Button> 
                          </div> *}
                        </div>
                      </Button>
                    </div>
                  </Form.Item> */}

                  <div className="link-type mb-3">
                    <span className="ac-create">
                      <Link>Have a promo code?</Link>
                    </span>
                  </div>

                  {/*   <div className="user-dirct mb-3">
                    <Form.Item
                      name="trem"
                      rules={[
                        {
                          required: false,
                          message: "Required",
                        },
                      ]}
                    >
                      <Checkbox onChange={onChange}>
                        I hereby certify that the information I provided herein
                        is truthful and correct to the best of my ability.
                      </Checkbox>
                    </Form.Item>
                  </div> */}

                  {/*   <div className="user-dirct">
                    <Form.Item
                      name="terms"
                      rules={[
                        {
                          required: false,
                          message: "Required",
                        },
                      ]}
                    >
                      <Checkbox onChange={onChange}></Checkbox> By clicking
                      “Sign up” below, I agree mynt’s User{" "}
                      <span className="ac-create">
                        <Link to={`/terms`} target="_blank">
                          Agreement
                        </Link>{" "}
                        and{" "}
                        <Link to="/privacypolicy" target="_blank">
                          {" "}
                          Privacy Policy.
                        </Link>
                      </span>
                    </Form.Item>
                    </div> */}

                  <Form.Item className="signin-footer mt-4 mb-3">
                    <Button
                      className="ant-btn-default"
                      type="submit"
                      htmlType="submit"
                    >
                      Sign up
                    </Button>
                    <Button
                      className="ant-btn-default"
                      type="submit"
                      htmlType="submit"
                      onClick={()=>navigate(-1)}
                    >
                      Back
                    </Button>
                  </Form.Item>

                  <div className="user-dirct">
                    <Form.Item className="mb-5">
                      <span className="ac-create">
                        <a
                          // to={`/login`}
                          target="_blank"
                          className="text-black"
                          onClick={() => navigate("/login")}
                        >
                          Sign in
                        </a>{" "}
                        to your Mynt account.
                      </span>
                    </Form.Item>
                  </div>
                </Form>
                <div className="signin-bottam-bar">
                  <span> For trademarks and patents, please see our</span>
                  <span
                    className="link-span"
                    onClick={() => navigate("/legal-notice")}
                  >
                    {" "}
                    Legal Notice.
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* <Modal className="modal01" open={isModalOpen} footer={false} onCancel={handleCancel} onOk={handleCancel}>
          <div className="language-select mt-3">
            <h4>Choose region and language</h4>
            <p>To create an account, please tell us where you live.</p>
          </div>

          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
            className="row-col">
            <Form.Item className="username-form" label="Country">
              <Select
                className="form-of-type"
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"

                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: 'jack',
                    label: 'Jack',
                  },
                  {
                    value: 'lucy',
                    label: 'Lucy',
                  },
                  {
                    value: 'tom',
                    label: 'Tom',
                  },
                ]}
              />

            </Form.Item><Form.Item className="username-form" label="Language">
              <Select
                className="form-of-type"
                showSearch
                placeholder="Select a person"
                optionFilterProp="children"

                onSearch={onSearch}
                filterOption={filterOption}
                options={[
                  {
                    value: 'jack',
                    label: 'Jack',
                  },
                  {
                    value: 'lucy',
                    label: 'Lucy',
                  },
                  {
                    value: 'tom',
                    label: 'Tom',
                  },
                ]}
              />
            </Form.Item>

          </Form>
          <div className="language-footer mt-3">

            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleCancel}>Apply</Button>
          </div>
        </Modal> */}

        {locationModal && (
          <Country
            value={userLocation}
            show={locationModal}
            hide={handleCancel}
            onSubmit={(data) => {
              setUserLocation(data);
            }}
          />
        )}

        {businessLocationModal && (
          <Country
            value={businessLocation}
            show={businessLocationModal}
            hide={() => showBusinessLocationModal(false)}
            onSubmit={(data) => {
              setBusinessLocation(data);
            }}
          />
        )}
      </Layout>
    </>
  );
};

const Country = ({ show, hide, onSubmit, value }) => {
  const [form] = Form.useForm();
  const [country, setCountry] = useState([]);
  const { request } = useRequest();
  const countryList = () => {
    request({
      url: "/common/country",
      method: "GEt",
      onSuccess: (res) => {
        const { data } = res;
        console.log(data, "country");
        setCountry(data.data);
      },
    });
  };

  useEffect(() => {
    countryList();
  }, []);

  useEffect(() => {
    if (!value)
      form.setFieldsValue({
        country_id: value.country._id,
        language: value.language,
      });
  }, [value, country]);

  const onFinish = (values) => {
    const { country_id, language } = values;
    if (onSubmit) {
      const selected = country.find((item) => item._id == country_id);
      onSubmit({ country: selected, language });
    }
    hide();
  };

  return (
    <Modal
      className="modal01"
      open={show}
      footer={false}
      onCancel={hide}
      onOk={hide}
    >
      <div className="language-select mt-3">
        <h4>Choose region and language</h4>
        <p>To create an account, please tell us where you live.</p>
      </div>

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="row-col"
      >
        <Form.Item
          className="username-form"
          label="Country"
          name="country_id"
          rules={[
            {
              required: true,
              message: "Please select country ",
            },
          ]}
        >
          <Select
            className="form-of-type"
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
          >
            {country.map((item) => (
              <Select.Option value={item._id} label={item.name}>
                {item.emoji} {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="username-form"
          label="Language"
          name={"language"}
          rules={[
            {
              required: true,
              message: "Please Select language ",
            },
          ]}
        >
          <Select
            className="form-of-type"
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onSearch={onSearch}
            filterOption={filterOption}
            options={[
              {
                value: "en",
                label: "English",
              },
              {
                value: "Espanol",
                label: "Español (Latinoamérica)",
              },
              {
                value: "Português",
                label: "Português (Brasil)",
              },
              {
                value: "Italiano",
                label: "Italiano",
              },
              {
                value: "Türkçe",
                label: "Türkçe",
              },
            ]}
          />
        </Form.Item>

        <div className="language-footer mt-3">
          <Button onClick={hide}>Cancel</Button>

          <Button htmlType="submit">Apply</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default SignUp;
