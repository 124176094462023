import { useState, useEffect, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import React, { Suspense, useContext } from "react";

import {
  Row,
  Col,
  Button,
  List,
  Avatar,
  Modal,
  Form,
  Input,
  message,
  Upload,
  Image,
  Typography,
  Dropdown as AntDropdown,
} from "antd";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import { AuthContext, useAuthContext } from "../../context/AuthContext";
import Logo from "../../assets/images/logo-white.svg";
import Usd from "../../assets/images/currency-usd.png";
import Exchange from "../../assets/images/exchange.png";
import User from "../../assets/images/user-bold.png";
import Nottfy from "../../assets/images/bell-outline.png";
import Toperrow from "../../assets/images/top-errow.svg";
import {
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
  SwapOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { uploadFile } from "react-s3";

import { NavLink, Link } from "react-router-dom";
import styled from "styled-components";
import avtar from "../../assets/images/learn.svg";
import useRequest from "../../hooks/useRequest";
import apiPath from "../../constants/api_urls";
import { useAppContext } from "../../context/AppContext";
import { currencyList } from "../../constants/var";
import { capitalizeStr } from "../../helper/functions";
import lang from "../../helper/langHelper";

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #fadb14;
  }
  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
`;

const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const wifi = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 107 107"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="logo-spotify" fill="#2EBD59" fillRule="nonzero">
        <path
          d="M53.5,0 C23.9517912,0 0,23.9517912 0,53.5 C0,83.0482088 23.9517912,107 53.5,107 C83.0482088,107 107,83.0482088 107,53.5 C107,23.9554418 83.0482088,0.00365063118 53.5,0 Z M78.0358922,77.1597407 C77.0757762,78.7368134 75.0204708,79.2296486 73.4506994,78.2695326 C60.8888775,70.5922552 45.0743432,68.8582054 26.4524736,73.1111907 C24.656363,73.523712 22.8675537,72.3993176 22.458683,70.6032071 C22.0461617,68.8070966 23.1669055,67.0182873 24.9666667,66.6094166 C45.3444899,61.9548618 62.8273627,63.9590583 76.9297509,72.5745479 C78.4995223,73.5419652 78.9996588,75.5899693 78.0358922,77.1597407 L78.0358922,77.1597407 Z M84.5814739,62.5973729 C83.373115,64.5614125 80.8030706,65.1747185 78.8426817,63.9700102 C64.4664961,55.1318321 42.5408052,52.5727397 25.5325145,57.7347322 C23.3275333,58.4027977 20.9984306,57.1579324 20.3267144,54.9566018 C19.6622996,52.7516206 20.9071648,50.4261685 23.1084954,49.7544524 C42.5371546,43.858683 66.6933811,46.7134766 83.2051859,56.8622313 C85.1692255,58.0705902 85.7898328,60.636984 84.5814739,62.5973729 Z M85.1436711,47.4253497 C67.8980894,37.1853292 39.4523712,36.2434664 22.9880246,41.2375299 C20.3449676,42.0406687 17.5485841,40.5475606 16.7490959,37.9045036 C15.9496076,35.2614466 17.4390652,32.4650631 20.0857728,31.6619243 C38.9850904,25.9267827 70.3987718,27.0329239 90.2509041,38.8171614 C92.627465,40.2299556 93.4087001,43.3001365 91.9995565,45.6730467 C90.5940635,48.0532583 87.5165814,48.838144 85.1436711,47.4253497 Z"
          id="Shape"
        ></path>
      </g>
    </g>
  </svg>,
];

const credit = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fill="#1890FF"
      d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
    ></path>
    <path
      fill="#1890FF"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
    ></path>
  </svg>,
];

const clockicon = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 6C11 5.44772 10.5523 5 10 5C9.44772 5 9 5.44772 9 6V10C9 10.2652 9.10536 10.5196 9.29289 10.7071L12.1213 13.5355C12.5118 13.9261 13.145 13.9261 13.5355 13.5355C13.9261 13.145 13.9261 12.5118 13.5355 12.1213L11 9.58579V6Z"
      fill="#111827"
    ></path>
  </svg>,
];

const data = [
  {
    title: "New message from Sophie",
    description: <>{clockicon} 2 days ago</>,

    avatar: avtar,
  },
  {
    title: "New album by Travis Scott",
    description: <>{clockicon} 2 days ago</>,

    avatar: <Avatar shape="square">{wifi}</Avatar>,
  },
  {
    title: "Payment completed",
    description: <>{clockicon} 2 days ago</>,
    avatar: <Avatar shape="square">{credit}</Avatar>,
  },
];

const menu = (
  <List
    min-width="100%"
    className="header-notifications-dropdown "
    itemLayout="horizontal"
    dataSource={data}
    renderItem={(item) => (
      <List.Item>
        <List.Item.Meta
          avatar={<Avatar shape="square" src={item.avatar} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    )}
  />
);

const Header = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn, userProfile } = useContext(AuthContext);

  useEffect(() => window.scrollTo(0, 0));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const withdrwalModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [ModalOpen, setWithModalOpen] = useState(false);

  const depositModal = () => {
    setWithModalOpen(true);
  };
  const handleDone = () => {
    setWithModalOpen(false);
  };
  const handleNo = () => {
    setWithModalOpen(false);
  };
  const props = {
    name: "file",
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          onClick={() => navigate("/")}
          className="ant-dropdawn"
          href="javascript:;"
        >
          {" "}
          {lang("Home")}{" "}
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          onClick={() => navigate("/Portfolio")}
          className="ant-dropdawn"
          href="javascript:;"
        >
          {lang("Portfolio")}
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          onClick={() => navigate("/Faq")}
          className="ant-dropdawn"
          href="javascript:;"
        >
          {lang("FAQ")}
        </a>
      ),
    },
    {
      key: "4",
      label: (
        <a
          onClick={() => navigate("/AccountMenu")}
          className="ant-dropdawn"
          href="javascript:;"
        >
          {lang("Account")}
        </a>
      ),
    },
  ];

  return (
    <>
      <LiveSpotPrices />
      <header>
        <Navbar expand="lg" className="p-0">
          <Navbar.Brand
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <img className="d-none d-md-block" src={Logo} />
            <h6 className="d-md-none username" onClick={() => navigate("/")}>
              <img className="d-md-block" src={Logo} />
              {/* {userProfile?.name ? capitalizeStr(userProfile?.name) :
              <img className="d-md-block" src={Logo} /> } */}
            </h6>
          </Navbar.Brand>
          <div className=" d-block d-lg-none">
            <div className="btn-header">
              {isLoggedIn ? (
                <>
                  {/* <Button className="ant-btn-header" type="button" onClick={() => navigate("/Notifications")} to="a"><img src={Nottfy} /> </Button> */}

                  {/* <AntDropdown
                    menu={{
                      items,
                    }}
                    trigger={['click']}
                    placement="bottomRight"
                    arrow
                  > */}
                  {/* <Button
                    onClick={() => {
                      navigate("/AccountMenu");
                    }}
                    className="ant-btn-header"
                    type="button"
                  >
                    <img src={User} />
                  </Button> */}
                  <Button
                    className="ant-btn-header btn-h02"
                    type="button"
                    onClick={() => navigate("/AccountMenu")}
                    to="a"
                  >
                    <img src={User} />{" "}
                  </Button>
                  {/* </AntDropdown> */}
                </>
              ) : (
                <>
                  <Button
                    className="ant-btn-header"
                    type="button"
                    onClick={() => navigate("/login")}
                    to="a"
                  >
                    {" "}
                    {lang("Sign In")}{" "}
                  </Button>
                </>
              )}
            </div>
          </div>
          <Navbar.Collapse id="">
            <Nav className="me-auto nav-items-name">
              <Nav.Link
                as={NavLink}
                to="/"
                className={`nav-menu${
                  location.pathname === "/" ? " active" : ""
                }`}
              >
                {lang("Home")}
              </Nav.Link>
              {/* <Nav.Link as={NavLink} to="/market" className={`nav-menu${location.pathname === '/market' ? ' active' : ''}`}>
                Market
              </Nav.Link> */}
              {isLoggedIn && (
                <Nav.Link
                  as={NavLink}
                  to="/portfolio"
                  className={`nav-menu${
                    location.pathname === "/portfolio" ? " active" : ""
                  }`}
                >
                  {lang("Portfolio")}
                </Nav.Link>
              )}
              {/* <Nav.Link as={NavLink} to="/Price" className={`nav-menu${location.pathname === '/Price' ? ' active' : ''}`}>
                Products
              </Nav.Link> */}
              {/* <Nav.Link as={NavLink} to="/storage-fees" className={`nav-menu${location.pathname === '/storage-fees' ? ' active' : ''}`}>
                Fees
              </Nav.Link> */}
              <Nav.Link
                as={NavLink}
                to="/Faq"
                className={`nav-menu${
                  location.pathname === "/Faq" ? lang("active") : ""
                }`}
              >
                {lang("FAQ")}
              </Nav.Link>
              <div className="btn-header d-lg-none mt-0">
                <Dropdown>
                  <Dropdown.Toggle
                    className="header-dropdawn"
                    variant="success"
                    id="dropdown-basic"
                  >
                    <Button className="ant-btn-header" type="button">
                      <img src={Usd} /> ${" "}
                    </Button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdawn-topbar">
                    <div className="submenu-dropdawn">
                      <ul>
                        <li>
                          <div className="topsearchbar">
                            <h5>{lang("Choose default currency")}</h5>
                          </div>
                        </li>

                        {currencyList.map((item) => (
                          <li
                            key={item.name}
                            className={`menu-body ${
                              item.name == "USD" ? lang("active") : ""
                            }`}
                          >
                            <Link>{item.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                {isLoggedIn ? (
                  <>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="header-dropdawn"
                        variant="success"
                        id="dropdown-basic"
                      >
                        <Button className="ant-btn-header" type="button">
                          <img src={Exchange} /> {lang("Transfer")}{" "}
                        </Button>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdawn-topbar">
                        <div className="submenu-dropdawn">
                          <ul>
                            <li>
                              <div className="topsearchbar">
                                <h5>{lang("Transfer")}</h5>
                              </div>
                            </li>
                            <li className="menu-body">
                              <Link onClick={depositModal}>
                                <VerticalAlignBottomOutlined />
                                {lang("Deposit cash")}
                              </Link>
                            </li>
                            <li className="menu-body">
                              <Link onClick={withdrwalModal}>
                                <VerticalAlignTopOutlined /> {lang("Withdraw cash")}
                              </Link>
                            </li>
                            <li>
                              <div className="topsearchbar">
                                <h5>{lang("Transfer")}</h5>
                              </div>
                            </li>
                            <li className="menu-body">
                              <Link>
                                {" "}
                                <SwapOutlined /> {lang("Move between accounts")}
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>

                    {/* <Button className="ant-btn-header" type="button" onClick={() => navigate("/account")} to="a"><img src={User} />  Account  </Button> */}
                    <Button
                      className="ant-btn-header"
                      type="button"
                      onClick={() => navigate("/Notifications")}
                      to="a"
                    >
                      <img src={Nottfy} />{" "}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="ant-btn-header"
                      type="button"
                      onClick={() => navigate("/login")}
                      to="a"
                    >
                      {" "}
                      {lang("Sign In")}{" "}
                    </Button>
                  </>
                )}
              </div>
            </Nav>
            <div className=" d-none d-lg-block">
              <div className="btn-header">
                {/* <Dropdown>
                <Dropdown.Toggle className="header-dropdawn" variant="success" id="dropdown-basic">
                  <Button className="ant-btn-header" type="button" ><img src={Usd} />   USD  </Button>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdawn-topbar">
                  <div className="submenu-dropdawn">
                    <ul>
                      <li>
                        <div className="topsearchbar">
                          <h5>Choose default currency</h5>
                        </div>
                      </li>

                      {
                        currencyList.map(item =>
                          <li key={item.name} className={`menu-body ${item.name == 'USD' ? ' active' : ''}`}>
                            <Link>{item.name}</Link>
                          </li>)
                      }
                    </ul>
                  </div>
                </Dropdown.Menu>
              </Dropdown> */}

                {isLoggedIn ? (
                  <>
                    {/* <Dropdown>
                    <Dropdown.Toggle className="header-dropdawn" variant="success" id="dropdown-basic">
                      <Button className="ant-btn-header" type="button"><img src={Exchange} />  Transfer  </Button>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdawn-topbar">
                      <div className="submenu-dropdawn">
                        <ul>
                          <li>
                            <div className="topsearchbar">
                              <h5>Transfer</h5>
                            </div>
                          </li>
                          <li className="menu-body">
                            <Link onClick={depositModal}><VerticalAlignBottomOutlined />Deposit cash</Link>
                          </li>
                          <li className="menu-body">
                            <Link onClick={withdrwalModal}><VerticalAlignTopOutlined /> Withdraw cash</Link>
                          </li>
                          <li>
                            <div className="topsearchbar">
                              <h5>Transfer</h5>
                            </div>
                          </li>
                          <li className="menu-body">
                            <Link> <SwapOutlined /> Move between accounts</Link>
                          </li>

                        </ul>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown> */}
                    {/* <Button className="ant-btn-header" type="button" onClick={() => navigate("/Notifications")} to="a"><img src={Nottfy} /> </Button> */}
                    <Button
                      className="ant-btn-header btn-h02"
                      type="button"
                      onClick={() => navigate("/account")}
                      to="a"
                    >
                      <img src={User} />{" "}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      className="ant-btn-header"
                      type="button"
                      onClick={() => navigate("/login")}
                      to="a"
                    >
                      {" "}
                      {lang("Sign In")}{" "}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </Navbar.Collapse>
        </Navbar>
      </header>
      {/* <div className="setting-drwer" onClick={showDrawer}>
        {setting}
      </div> */}
      <Row>
        <Col span={24} xs={20} md={6}>
          {/* <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {name?.replace("/", " / ")}
            </Breadcrumb.Item>
          </Breadcrumb> */}
        </Col>
        {/* <Col span={24} xs={4} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={placement}
            visible={visible}
          >
            <div layout="vertical">
              <div className="header-top">
                <Title level={4}>
                  Configurator
                  <Text className="subtitle">See our dashboard options.</Text>
                </Title>
              </div>

              <div className="sidebar-color">
                <Title level={5}>Sidebar Color</Title>
                <div className="theme-color mb-2">
                  <ButtonContainer>
                    <Button
                      type="primary"
                      onClick={() => handleSidenavColor("#1890ff")}
                    >
                      1
                    </Button>
                    <Button
                      type="success"
                      onClick={() => handleSidenavColor("#52c41a")}
                    >
                      1
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => handleSidenavColor("#d9363e")}
                    >
                      1
                    </Button>
                    <Button
                      type="yellow"
                      onClick={() => handleSidenavColor("#fadb14")}
                    >
                      1
                    </Button>

                    <Button
                      type="black"
                      onClick={() => handleSidenavColor("#111")}
                    >
                      1
                    </Button>
                  </ButtonContainer>
                </div>

                <div className="sidebarnav-color mb-2">
                  <Title level={5}>Sidenav Type</Title>
                  <Text>Choose between 2 different sidenav types.</Text>
                  <ButtonContainer className="trans">
                    <Button
                      type={sidenavType === "transparent" ? "primary" : "white"}
                      onClick={() => {
                        handleSidenavType("transparent");
                        setSidenavType("transparent");
                      }}
                    >
                      TRANSPARENT
                    </Button>
                    <Button
                      type={sidenavType === "white" ? "primary" : "white"}
                      onClick={() => {
                        handleSidenavType("#fff");
                        setSidenavType("white");
                      }}
                    >

          WHITE
                    </Button>
                  </ButtonContainer>
                </div>
                <div className="fixed-nav mb-2">
                  <Title level={5}>Navbar Fixed </Title>
                  <Switch onChange={(e) => handleFixedNavbar(e)} />
                </div>
                <div className="ant-docment">
                  <ButtonContainer>
                    <Button type="black" size="large">
                      FREE DOWNLOAD
                    </Button>
                    <Button size="large">VIEW DOCUMENTATION</Button>
                  </ButtonContainer>
                </div>
                <div className="viewstar">
                  <a href="#pablo">{<StarOutlined />} Star</a>
                  <a href="#pablo"> 190</a>
                </div>

                <div className="ant-thank">
                  <Title level={5} className="mb-2">
                    Thank you for sharing!
                  </Title>
                  <ButtonContainer className="social">
                    <Button type="black">{<TwitterOutlined />}TWEET</Button>
                    <Button type="black">{<FacebookFilled />}SHARE</Button>
                  </ButtonContainer>
                </div>
              </div>
            </div>
          </Drawer> 
          <Link to="/sign-in" className="btn-sign-in">
  {profile}
  <span>Sign in</span>
</Link>
<Input
  className="header-search"
  placeholder="Type here..."
  prefix={<SearchOutlined />}
/>
        </Col> */}
      </Row>

      <Modal
        className="modal01"
        title={lang("Request Withdraw")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout="vertical" className="row-col">
          <Form.Item
            className="username-form"
            label={lang("Withdraw Amount")}
            name="email"
          >
            <Input
              className="form-of-type"
              type="text"
              placeholder={lang("Enter Amount")}
            />
          </Form.Item>
          <Form.Item className="username-form" label={lang("Comment")} name="Price">
            <Input
              className="form-of-type"
              type="text"
              placeholder={lang("Enter Comment")}
            />
          </Form.Item>
          <div className="mx-auto text-center">
            <Button>{lang("Request Withdraw")}</Button>
          </div>
        </Form>
      </Modal>

      <Modal
        className="modal01"
        title={lang("Request Deposit")}
        open={ModalOpen}
        onOk={handleDone}
        onCancel={handleNo}
        footer={false}
      >
        <Form layout="vertical" className="row-col">
          <Form.Item
            className="username-form"
            label={lang("Deposit Amount")}
            name="text"
          >
            <Input
              className="form-of-type"
              type="text"
              placeholder={lang("Enter Amount")}
            />
          </Form.Item>
          <Form.Item
            className="username-form"
            label={lang("Receipt Number")}
            name="text"
          >
            <Input
              className="form-of-type"
              type="text"
              placeholder={lang("Enter Receipt Number")}
            />
          </Form.Item>
          <Form.Item
            className="username-form"
            label={lang("Upload Profile")}
            name="image"
          >
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>{lang("Upload")}</Button>
            </Upload>

            {/* <div className="mt-3">
                      <Image width={300} src={image}></Image>
                    </div> */}
          </Form.Item>

          <div className="mx-auto text-center">
            <Button>{lang("Request Withdraw")}</Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const LiveSpotPrices = ({}) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const { prices, goldPrice, silverPrice,language } = useAppContext();


  const memoizedTickerTapeWidget = useMemo(() => {
    return <TradingViewTickerTapeWidget />;
  }, []);

  return (
    <div className="categrory-header">
      <ul>
        {prices.map((price) => (
          <li className="categrory-nav" key={price._id}>
            <h6>{language == "en" ? price.categories : price.ar_categories}:</h6>
            <h6>
              {price.atomicSymbol == "XAU" &&
                goldPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              {price.atomicSymbol == "XAG" &&
                silverPrice.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
            </h6>
            <span>
              <img src={Toperrow} />  
              $0.02
            </span>
          </li>
        ))}
        {/* <div className="mini-header" >
        {memoizedTickerTapeWidget}
        </div> */}
      </ul>
    </div>
  );
};

const TradingViewTickerTapeWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.innerHTML = JSON.stringify({
      symbols: [
        {
          description: lang("Gold"),
          proName: "OANDA:XAUUSD",
        },
        {
          description: lang("Silver"),
          proName: "OANDA:XAGUSD",
        },
      ],
      height: "30px",
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "regular",
      showSymbolLogo: false,
      colorTheme: "light",
      locale: "en",
    });

    document
      .getElementsByClassName("tradingview-widget-container__widget_1")[0]
      .appendChild(script);

    return () => {
      // Cleanup
      if (
        document.getElementsByClassName(
          "tradingview-widget-container__widget_1",
        )[0]?.innerHTML
      ) {
        document.getElementsByClassName(
          "tradingview-widget-container__widget_1",
        )[0].innerHTML = "";
      }
    };
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget_1"></div>
    </div>
  );
};

export default Header;
