import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import "antd/dist/antd.css";
import "antd/dist/antd.min.css";
<script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>  

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
  // <React.StrictMode>
  // </React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

