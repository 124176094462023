import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Select,
  Button,
  Space,
  Table,
  Tag,
  Tabs,
  InputNumber,
  DatePicker,
} from "antd";

import ArrowRightOutlined from "antd/es/icon";
import { ShowToast, Severty } from "../../helper/toast";
import "react-phone-input-2/lib/style.css";

import React, { useState, useContext, useEffect } from "react";
import useRequest from "../../hooks/useRequest";
import { AuthContext } from "../../context/AuthContext";

import encryptDecrypt from "../../helper/encryptDecrypt";
import { Link, useNavigate } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Container } from "react-bootstrap";
import securepayment from "../../assets/images/secure-payment-fill.png";
import Cash from "../../assets/images/icon/cash.svg";
import Curncy from "../../assets/images/icon/curncy.svg";
import Bank from "../../assets/images/icon/bank.svg";
import Mynt from "../../assets/images/icon/mynt.svg";
import Credit from "../../assets/images/icon/credit.svg";
import Paypal from "../../assets/images/icon/paypal.svg";
import BankAcc from "../../assets/images/icon/bank-acc.svg";
import Bitcoin from "../../assets/images/icon/bitcoin.svg";
import BankCheck from "../../assets/images/icon/bank-check.svg";
import Bankwire from "../../assets/images/icon/bank-wire.svg";

import Header from "../../components/layout/Header";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import { useAppContext } from "../../context/AppContext";
import moment from "moment";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function AutoInvestForm() {
  const { request } = useRequest();
  const { userProfile } = useContext(AuthContext);
  const { prices, isMobile } = useAppContext();
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("usd");
  const [paymentMethod, setPayment] = useState("cashBalance");
  const [tabValue, setTabValue] = useState();
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onSubmit(values);
  };

  const onSubmit = (values) => {
    const { date, categoryId, repeat, payment_method } = values;

    const payload = {
      payment_method: paymentMethod,
      price,
      date,
      categoryId,
      repeat,
      type,
    };
    console.log(payload, "payload------------>");
    setLoading(true);

    request({
      url: "/app/recurrings/add-buys ",
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          const { list } = data;
          ShowToast(data.message, Severty.SUCCESS);
          console.log(data.message, "data.message-------1----------->");
          navigate(-1);
        } else {
          ShowToast(data.message, Severty.ERROR);
          console.log(data.message, "data.message----2-------------->");
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        console.log(
          error.response.data.message,
          "data.message-err----------------->"
        );

        setLoading(false);
      },
    });
  };

  return (
    <>
      <section className="main">
        <Header />

        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">{lang("Auto Invest")}</div>
                {/* <div className="payment-methods">
                    <div className="auto-invest-w">
                      <img src={securepayment} />
                      <h4>Set up Auto Invest Now</h4>
                      <p>Mynt Auto Invest program allows you to make recurring purchases of digital metals in increments that fit your timeframe and budget.</p>
                      <div className="password-page-btn mt-4">
                        <Button className="security-btn">Add New</Button>
                      </div>
                    </div>
  
                  </div> */}
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  className="row-col mt-3"
                >
                  <Form.Item
                    className="username-form"
                    label={lang("Product")}
                    name="categoryId"
                    rules={[
                      {
                        required: true,
                        message: lang("Please select product"),
                      },
                    ]}
                    initialValue={"66069035fa639b23dcf4b3a6"}
                  >
                    <Select
                      placeholder={lang(`Select Product`)}
                      className="auto-innves"
                    >
                      {prices.map((item) => (
                        <Select.Option
                          className="select-opt-inn"
                          value={item._id}
                          key={item._id}
                        >
                          {item.categories}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    label={lang("Amount")}
                    name="type"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (price <= 0) {
                            return Promise.reject(
                              new Error(
                                lang("Please enter an amount greater than 0.00")
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <div className="currncy-select">
                      <Select
                        defaultValue={"$"}
                        className="auto-innves"
                        onSelect={(value) => setType(value)}
                      >
                        <Select.Option className="select-opt-inn" value={"usd"}>
                          $
                        </Select.Option>
                        <Select.Option className="select-opt-inn" value={"qty"}>
                          {lang("Qty")}
                        </Select.Option>
                      </Select>

                      <div className="right-form-currncy">
                        <InputNumber
                          type="text"
                          placeholder="0.00"
                          onChange={(value) => {
                            setPrice(value);
                          }}
                        />

                        <span>{type == "usd" ? `$` : "OZ"}</span>
                      </div>
                    </div>
                  </Form.Item>

                  {/* <Form.Item
                                        className="username-form"
                                        label="Amount"
                                        name="price"
                                        rules={[{
                                            required: true,
                                            message: 'Please enter amount'
                                        }]}
                                    >
                                        
                                    </Form.Item> */}

                  <Form.Item
                    className="username-form"
                    label={lang("Payment Method")}
                    name=""
                  >
                    <Select
                      defaultValue={"cashBalance"}
                      className="buy-select m-0"
                    >
                      <Select.Option value={"cashBalance"}>
                        {" "}
                        <li
                          className="buy-now-list"
                          onClick={() => {
                            setPayment("cashBalance");
                          }}
                        >
                          <Link>
                            <img src={Cash} />
                            <div className="dropdawn-visa-text">
                              <h6>
                                {lang("Cash Balance")}
                                {" :"}
                                <span>
                                  {" "}
                                  $
                                  {userProfile &&
                                    userProfile.cashBalance.toFixed(2)}
                                </span>
                              </h6>
                              {/* <p>$0.01 minimum</p> */}
                            </div>
                          </Link>
                        </li>{" "}
                      </Select.Option>
                      <Select.Option value={"credit/debit card"}>
                        {" "}
                        <li className="buy-now-list">
                          <Link>
                            <img src={Credit} />
                            <div
                              className="dropdawn-visa-text"
                              onClick={() => {
                                setPayment("credit/debit card");
                              }}
                            >
                              <h6>{lang("Credit/Debit Card")}</h6>
                              <p>3.99 % {lang("Service Fee")}</p>
                            </div>
                          </Link>
                        </li>{" "}
                      </Select.Option>
                      <Select.Option value={"paypal"}>
                        <li
                          className="buy-now-list"
                          onClick={() => {
                            setPayment("paypal");
                          }}
                        >
                          <Link>
                            <img src={Paypal} />
                            <div className="dropdawn-visa-text">
                              <h6>PayPal</h6>
                              <p>3.99 % {lang("Service Fee")}</p>
                            </div>
                          </Link>
                        </li>{" "}
                      </Select.Option>
                      <Select.Option value={"bankWire"}>
                        {" "}
                        <li
                          className="buy-now-list"
                          onClick={() => {
                            setPayment("bankWire");
                          }}
                        >
                          <Link>
                            <img src={Bankwire} />
                            <div className="dropdawn-visa-text">
                              <h6>{lang("Bank Wire")}</h6>
                              <p>{lang("No Fee")}</p>
                            </div>
                          </Link>
                        </li>{" "}
                      </Select.Option>
                      <Select.Option value={"bank-account"}>
                        <li
                          className="buy-now-list"
                          onClick={() => {
                            setPayment("bank-account");
                          }}
                        >
                          <Link>
                            <img src={BankAcc} />
                            <div className="dropdawn-visa-text">
                              <h6>{lang("Bank Account")}</h6>
                              <p>{lang("No Fee")}</p>
                            </div>
                          </Link>
                        </li>{" "}
                      </Select.Option>
                      <Select.Option value={"bitcoin"}>
                        {" "}
                        <li
                          className="buy-now-list"
                          onClick={() => {
                            setPayment("bitcoin");
                          }}
                        >
                          <Link>
                            <img src={Bitcoin} />
                            <div className="dropdawn-visa-text">
                              <h6>BitCoin</h6>
                              <p>1.99 % {lang("Service Fee")}</p>
                            </div>
                          </Link>
                        </li>
                      </Select.Option>
                      <Select.Option value={"echeck"}>
                        {" "}
                        <li className="buy-now-list">
                          <Link>
                            <img src={BankCheck} />
                            <div
                              className="dropdawn-visa-text"
                              onClick={() => {
                                setPayment("echeck");
                              }}
                            >
                              <h6>{lang("Personal Check")}</h6>
                              <p>{lang("No Fee")}</p>
                            </div>
                          </Link>
                        </li>
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                      className="username-form"
                      label="Amount"
                      name=""
                    >
                       <Select defaultValue={1} className="buy-select m-0">
                        <Select.Option value={1}> <li className="buy-now-list" >
                          <Link>
                            <img src={Cash} />
                            <div className="dropdawn-visa-text">
                              <h6>Cash Balance: <span> $</span></h6>
                           
                            </div>
                          </Link>
                        </li> </Select.Option>
                        <Select.Option> <li className="buy-now-list">
                          <Link>
                            <img src={Credit} />
                            <div className="dropdawn-visa-text">
                              <h6>Credit/Debit Card</h6>
                              <p>3.99 % Service Fee</p>
                            </div>
                          </Link>
                        </li> </Select.Option>
                        <Select.Option><li className="buy-now-list">
                          <Link>
                            <img src={Paypal} />
                            <div className="dropdawn-visa-text">
                              <h6>PayPal</h6>
                              <p>3.99 % Service Fee</p>
                            </div>
                          </Link>
                        </li> </Select.Option>
                        <Select.Option> <li className="buy-now-list" >
                          <Link>
                            <img src={Bankwire} />
                            <div className="dropdawn-visa-text">
                              <h6>Bank Wire</h6>
                              <p>No Fee</p>
                            </div>
                          </Link>
                        </li> </Select.Option>
                        <Select.Option><li className="buy-now-list">
                          <Link>
                            <img src={BankAcc} />
                            <div className="dropdawn-visa-text">
                              <h6>Bank Account</h6>
                              <p>No Fee</p>
                            </div>
                          </Link>
                        </li> </Select.Option>
                        <Select.Option>  <li className="buy-now-list" >
                          <Link>
                            <img src={Bitcoin} />
                            <div className="dropdawn-visa-text">
                              <h6>BitCoin</h6>
                              <p>1.99 % Service Fee</p>
                            </div>
                          </Link>
                        </li></Select.Option>
                        <Select.Option>  <li className="buy-now-list">
                          <Link>
                            <img src={BankCheck} />
                            <div className="dropdawn-visa-text">
                              <h6>Personal Check</h6>
                              <p>No Fee</p>
                            </div>
                          </Link>
                        </li></Select.Option>
                      </Select>
                    </Form.Item> */}

                  <Row gutter={24}>
                    <Col span={24} md={8}>
                      <Form.Item
                        className="username-form-date"
                        label={lang("Starting From")}
                        name="date"
                        // name="startDate"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select start date"),
                          },
                        ]}
                        initialValue={moment().add(1, "days")}
                      >
                        <DatePicker
                          placeholder="4/30/2024"
                          disabledDate={(current) =>
                            current && current < moment().startOf("day")
                          }
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col span={24} md={8}>
                      <Form.Item
                        className="username-form-date"
                        label="Ending On"
                        name="endDate"
                        // name="date"
                        initialValue={moment().add(1, "years")}
                        rules={[
                          {
                            required: true,
                            message: "Please select end date",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="4/30/2024"
                          disabledDate={(current) =>
                            current && current < moment().startOf("day")
                          }
                        />
                      </Form.Item>
                    </Col> */}
                    <Col span={24} md={8}>
                      <Form.Item
                        className="username-form"
                        label={lang("Repeat")}
                        name="repeat"
                        rules={[
                          {
                            required: true,
                            message: lang("Please Select Frequency"),
                          },
                        ]}
                      >
                        <Select defaultValue={""} className="auto-innves">
                          {/* 0,10,20,30,40 */}
                          <Select.Option className="select-opt-inn" value={``}>
                            <span>{lang("Please Select Frequency")}</span>
                          </Select.Option>
                          <Select.Option
                            className="select-opt-inn"
                            value={`Daily`}
                          >
                            {lang("Daily")}{" "}
                          </Select.Option>
                          <Select.Option
                            className="select-opt-inn"
                            value={`Weekly`}
                          >
                            {lang("Weekly")}
                          </Select.Option>
                          <Select.Option
                            className="select-opt-inn"
                            value={"Biweekly"}
                          >
                            {lang("Biweekly")}{" "}
                          </Select.Option>
                          <Select.Option
                            className="select-opt-inn"
                            value={"Monthly"}
                          >
                            {lang("Monthly")}{" "}
                          </Select.Option>
                          <Select.Option
                            className="select-opt-inn"
                            value={"Quarterly"}
                          >
                            {lang("Quarterly")}{" "}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="auto-innes">
                    <button className="btn">{lang("Continue")}</button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AutoInvestForm;
