export const currencyList =[
    {
      name: 'USD',
      symbol: '$',
      image: 'usd_image_url', // You can replace this with the actual image URL
      fullName: 'United States Dollar'
    },
    // {
    //   name: 'AUD',
    //   symbol: 'A$',
    //   image: 'aud_image_url', // You can replace this with the actual image URL
    //   fullName: 'Australian Dollar'
    // },
    // {
    //   name: 'CAD',
    //   symbol: 'C$',
    //   image: 'cad_image_url', // You can replace this with the actual image URL
    //   fullName: 'Canadian Dollar'
    // },
    // {
    //   name: 'EUR',
    //   symbol: '€',
    //   image: 'eur_image_url', // You can replace this with the actual image URL
    //   fullName: 'Euro'
    // },
    // {
    //   name: 'GPB',
    //   symbol: '£',
    //   image: 'gbp_image_url', // You can replace this with the actual image URL
    //   fullName: 'British Pound Sterling'
    // },
    // {
    //   name: 'HKD',
    //   symbol: 'HK$',
    //   image: 'hkd_image_url', // You can replace this with the actual image URL
    //   fullName: 'Hong Kong Dollar'
    // },
    // {
    //   name: 'SGD',
    //   symbol: 'S$',
    //   image: 'sgd_image_url', // You can replace this with the actual image URL
    //   fullName: 'Singapore Dollar'
    // }
  ]
//['USD','AUD','CAD','EUR','GPB','HKD','SGD']