import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Tabs,
  Typography
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import 'react-phone-input-2/lib/style.css';
import { Severty, ShowToast } from "../../helper/toast";

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import useRequest from "../../hooks/useRequest";

import { useNavigate } from "react-router-dom";
import encryptDecrypt from "../../helper/encryptDecrypt";
import useDebounce from "../../hooks/useDebounce";


import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Container } from "react-bootstrap";
import payments03 from '../../assets/images/Possible-payments 02.svg';
import payments04 from '../../assets/images/Possible-payments 03.svg';
import payments02 from '../../assets/images/Possible-payments.svg';
import payments01 from '../../assets/images/Possiblepayments01.svg';
import iphone from '../../assets/images/iphone-app-store.svg';
import Playstore from '../../assets/images/play-app-store.svg';
import securepayment from '../../assets/images/secure-payment-fill.png';
import AccountSideNav from "../../components/layout/AccountSideNav";
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";

function InvoiceIndex() {
  const { request } = useRequest();
  const { Title } = Typography;
  const { setIsLoggedIn, setUserProfile } = useContext(AuthContext);
  const [searchText, setSearchText] = useState('');
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [list, setList] = useState([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [customerGraphData, setGraphCustomerData] = useState({});
  const [visible, setVisible] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [viewData, setViewData] = useState();
  const [tabValue, setTabValue] = useState();
  const [productList, setProductList] = useState([]);
  const [visibleView, setVisibleView] = useState(false);
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const onFinish = (values) => {
    onSubmit(values)
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const onSubmit = (values) => {
    const { email, password } = values
    if (!email) return ShowToast('Please enter email to sign in', Severty.ERROR)
    const payload = { password }
    if (!email) return ShowToast('Please enter valid email ', Severty.ERROR)
    setLoading(true)
    payload.email = email
    request({
      url: '/v1/webaapi/login',
      method: 'POST',
      data: payload,
      onSuccess: (data) => {
        setLoading(false)
        if (data.status) {
          console.log(data)
          setIsLoggedIn(true)
          if (rememberMe) {
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(values.password);

            localStorage.setItem('rememberMe', 'true');
            localStorage.setItem('ykmCe2AYEFTHobn', emailEncrypt);
            localStorage.setItem('ouUsippetc8S4Ry', passwordEncrypt);
          }
          else {
            localStorage.removeItem('ykmCe2AYEFTHobn');
            localStorage.removeItem('ouUsippetc8S4Ry');
          }
          localStorage.setItem('userProfile', JSON.stringify(data.data))
          ShowToast(data.message, Severty.SUCCESS)
          setUserProfile(data.data)
          navigate('/')
        } else {
          ShowToast(data.message, Severty.ERROR)

        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR)
        setLoading(false)
      },
    })
  }
  useEffect(() => {
    customerMonthlyCount({})
  }, [])

  const handleTabChange = (status) => {
    setTabValue(status)
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const customerMonthlyCount = (data) => {
    const Cmonths = data?.userMonthlyGraph?.map((item) => item?.month);
    const Ccounts = data?.userMonthlyGraph?.map((item) => item?.count);

    const customerChartData = {
      series: [
        {
          name: "User",
          data: Ccounts,
        },
      ],
      options: {
        chart: {
          height: 250,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },

        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: Cmonths,
        },
      },
    };
    setGraphCustomerData(customerChartData);
  };

  const [show, setShow] = useState(false);



  return (
    <>
      <section className="main">
        <Header />


        <div className="account-outers">
          <Row>
            <AccountSideNav />
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="table-headding">
                  Invoice
                </div>
                <Tabs className="transactions-tab invoice-tab" onTabClick={handleTabChange} >
                  <TabPane tab="All" key="Timesheet" className="tab-pane-transaction ">
                    <Row gutter={16}>
                      <Col sm={24} md={24}>
                        <div className="payment-methods mt-0">
                          <div className="auto-invest-w">
                            <img src={securepayment} />
                            <h4>No Invoice History</h4>
                            <p>Storage invoice will be sent quarterly to your email address on file. Visit your portfolio to view the current holdings in storage.</p>
                            <div className="password-page-btn">
                              <Button className="security-btn">View Portfolio</Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Cancellation" key="Cancellation" className="tab-pane-transaction">
                    <Row gutter={16}>
                      <Col sm={24} md={24}>
                        <div className="payment-methods mt-0">
                          <div className="auto-invest-w">
                            <img src={securepayment} />
                            <h4>No Invoice History</h4>
                            <p>Storage invoice will be sent quarterly to your email address on file. Visit your portfolio to view the current holdings in storage.</p>
                            <div className="password-page-btn">
                              <Button className="security-btn">View Portfolio</Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Storage" key="Storage" className="tab-pane-transaction">
                    <Row gutter={16}>
                      <Col sm={24} md={24}>
                        <div className="payment-methods mt-0">
                          <div className="auto-invest-w">
                            <img src={securepayment} />
                            <h4>No Invoice History</h4>
                            <p>Storage invoice will be sent quarterly to your email address on file. Visit your portfolio to view the current holdings in storage.</p>
                            <div className="password-page-btn">
                              <Button className="security-btn">View Portfolio</Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            </Col>

          </Row>
        </div>


      </section>
      <Footer />
    </>
  );
}



export default InvoiceIndex;
