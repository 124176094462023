import { Button, Col, Form, Row, Skeleton, Table, Typography } from "antd";

import "react-phone-input-2/lib/style.css";
import { Severty, ShowToast } from "../../helper/toast";

import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";

import { useNavigate } from "react-router-dom";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import securepayment from "../../assets/images/secure-payment-fill.png";

import moment from "moment";
import AccountSideNav from "../../components/layout/AccountSideNav";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import { useAppContext } from "../../context/AppContext";
import { BackArrow } from "./Index";
import lang from "../../helper/langHelper";

function AutoInvest() {
  const { request } = useRequest();
  const { Title } = Typography;
  const { isMobile } = useAppContext();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const getData = (values) => {
    setLoading(true);

    request({
      url: "/app/recurrings/get-buys",
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        if (data?.status) {
          // ShowToast(data.message, Severty.SUCCESS)
          console.log(data.data.data, "data.data.data");
          setList(data?.data?.data);
        } else {
          ShowToast(data?.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response?.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: lang("S. No"),
      dataIndex: "index",
      key: "index",
      render: (value, item, index) =>
        `${
          pagination.current === 1
            ? index + 1
            : (pagination.current - 1) * 10 + (index + 1)
        }`,
    },
    {
      title: lang("Product"),
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { categoryData }) => {
        return categoryData ? categoryData?.categories : "-";
      },
    },
    {
      title: lang("Type"),
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { type }) => {
        console.log(type, "type");
        return type ? type.toUpperCase() : "$";
      },
    },
    {
      title: lang("Amount"),
      className: "tavble",
      dataIndex: "orderId",
      key: "orderId",
      render: (_, { price, type }) => {
        return price
          ? type == "qty"
            ? `$${price.toFixed(3)}`
            : `$${price.toFixed(2)}`
          : "-";
      },
    },

    {
      title: lang("Repeat"),
      className: "tavble",
      key: "amount",
      dataIndex: "amount",
      render: (_, { repeat }) => {
        return repeat ? repeat : "-";
      },
    },
    {
      title: lang("Start Date"),
      // className: 'table',
      dataIndex: "dateOfTransaction",
      key: "dateOfTransaction",
      render: (_, { date }) => date && moment(date).format("lll"),
    },
    // {
    //   title: 'End Date',
    //   // className: 'table',
    //   dataIndex: 'dateOfTransaction',
    //   key: 'dateOfTransaction',
    //   render: (_, { endDate }) => endDate && moment(endDate).format('lll'),
    // },
  ];

  return (
    <>
      <section className="main">
        <Header />

        <div className="account-outers">
          <Row>
            {!isMobile ? <AccountSideNav /> : <BackArrow />}
            <Col span={24} md={16} lg={18} xl={15} className="mx-auto">
              <div className="account-main">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="table-headding">{lang("Auto Invest")}</div>
                  {list.length ? (
                    <Button
                      className=" "
                      onClick={() => navigate("/AutoInvest/create")}
                    >
                      {lang("Add New")}
                    </Button>
                  ) : null}
                </div>
                {loading ? (
                  [1, 2, 3, 4].map((item) => <Skeleton active key={item} />)
                ) : !list.length ? (
                  <div className="payment-methods">
                    <div className="auto-invest-w">
                      <img src={securepayment} />
                      <h4>{lang("Set up Auto Invest Now")}</h4>
                      <p>
                        {lang(
                          "Mynt Auto Invest program allows you to make recurring purchases of digital metals in increments that fit your timeframe and budget."
                        )}
                      </p>
                      <div className="password-page-btn mt-4">
                        <Button
                          className="security-btn"
                          onClick={() => navigate("/AutoInvest/create")}
                        >
                          {lang("Add New")}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3">
                    <Table
                      className="transactions-table table-responsive"
                      columns={columns}
                      dataSource={list}
                      pagination={{
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                      }}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default AutoInvest;
